import { repositoryFactory } from '@/repositories/repositoryFactory';

const utilityInvoiceRepository = repositoryFactory.utilityInvoice;

export default {
  async fetchAllUtilityInvoices({ commit }, query) {
    commit('startLoading');
    const utilityInvoices = await utilityInvoiceRepository.getAll(query);
    commit('fetchAllUtilityInvoicesSucceeded', { utilityInvoices });
    commit('stopLoading');
  },
  async fetchUtilityInvoiceById({ commit }, { id }, store) {
    commit('startLoading');
    const utilityInvoice = await utilityInvoiceRepository.get(id);
    if (store) {
      commit('fetchUtilityInvoiceByIdSucceeded', { utilityInvoice });
    }
    commit('stopLoading');
    return utilityInvoice;
  },
  async deleteUtilityInvoiceById({ commit }, { id }) {
    await utilityInvoiceRepository.delete(id);
    commit('deleteUtilityInvoiceSucceeded');
  },
  async createUtilityInvoice({ commit }, { utilityInvoice }) {
    commit('startLoading');
    await utilityInvoiceRepository.create(utilityInvoice);
    commit('createUtilityInvoiceSucceeded');
    commit('stopLoading');
  },
  async massCreateUtilityInvoice({ commit }, { data, files }) {
    commit('startLoading');
    const response = await utilityInvoiceRepository.massCreateUtilityInvoice({data, files,});
    commit('createUtilityInvoiceSucceeded');
    commit('stopLoading');
    return response;
  },
  async updateUtilityInvoice({ commit }, { utilityInvoice }) {
    commit('startLoading');
    await utilityInvoiceRepository.update(utilityInvoice.id, utilityInvoice);
    commit('updateUtilityInvoiceSucceeded');

    commit('stopLoading');
  },
};
