export default {
  updatePropertyManagerSucceeded(state, { propertyManager }) {
    state.propertyManager = propertyManager;
    state.notification = {
      display: true,
      type: 'success',
      message: 'The property manager have been updated successfully!',
      timeout: 2000,
    };
  },
  updatePropertyManagerFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an issue updating the property manager!',
      timeout: 2000,
    };
  },
  fetchAllPropertyManagersSucceeded(state, { propertyManagers }) {
    state.propertyManagers = propertyManagers;
  },
  fetchAllPropertyManagersFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error loading the Property Managers.',
      timeout: 3000,
    };
  },
  fetchOwnPropertyManagerSucceeded(state, { propertyManager }) {
    state.propertyManager = propertyManager;
  },
  fetchOwnPropertyManagerFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error loading the Property Manager.',
      timeout: 3000,
    };
  },
  fetchPropertyManagerByIDSucceeded(state, { propertyManager }) {
    state.propertyManager = propertyManager;
  },
  fetchPropertyManagerByIDFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error loading the Property Manager.',
      timeout: 3000,
    };
  },
  deletePropertyManagerSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'Property Manager successfully deleted.',
      timeout: 3000,
    };
  },
  deletePropertyManagerFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error deleting the Property Manager.',
      timeout: 3000,
    };
  },
  createPropertyManagerSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The Property Manager has been created successfully!',
      timeout: 2000,
    };
  },
  createPropertyManagerFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error creating the Property Manager.',
      timeout: 3000,
    };
  },

};
