import _ from 'lodash';

function transform(obj, transformFunction) {
  if (_.isArray(obj)) {
    return obj.map((item) => transform(item, transformFunction));
  }

  if (_.isPlainObject(obj)) {
    const newObject = {};
    _.forEach(obj, (val, key) => {
      if (val == null) return;

      let value = val;
      if (_.isPlainObject(val) || _.isArray(val)) {
        value = transform(val, transformFunction);
      }
      newObject[transformFunction(key)] = value;
    });
    return newObject;
  }

  return obj;
}

function snakeCase(obj) {
  const snakeCaseObj = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(obj)) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      snakeCaseObj[_.snakeCase(key)] = obj[key];
    }
  }
  return snakeCaseObj;
}

function buildResponse(response) {
  return transform(response, _.camelCase);
}

function buildRequest(request) {
  let payload;
  if (Array.isArray(request)) {
    // eslint-disable-next-line no-restricted-syntax
    for (let [idx, element] of request.entries()) {
      if (element) {
        request[idx] = snakeCase(element);
      }
    }
    payload = request;
  } else {
    payload = snakeCase(request);
  }
  delete payload.id;
  return payload;
}

export { buildResponse, buildRequest };
