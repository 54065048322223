import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from '@/views/ApplicationDashboard.vue';
import Authentication from '@/views/ApplicationAuthentication.vue';
import Login from '@/components/authentication/ApplicationLogin.vue';
import Settings from '@/components/settings/AccountSettings.vue';
import {
  ADMIN_MOS,
  COORDINADOR_MOS,
  PROCESAMIENTO_MOS,
  RECEPCION_MOS,
  ADMIN_CLIENTE,
  STAFF_CLIENT,
  VENDOR_EXTERNAL,
} from '@/constants';

Vue.use(Router);

const getStore = () => JSON.parse(localStorage.vuex);

const mapAuthorizedSections = (sections) => sections.reduce((e, item) => {
  if (item.type === 'nested') {
    const nestedSections = mapAuthorizedSections(item.nestedItems);
    Object.assign(e, nestedSections);
  } else {
    e[item.link] = true;
  }
  return e;
}, {});

const getAllowedSections = () => {
  const store = getStore();
  return mapAuthorizedSections(store.sections.items);
};

const getDefaultSection = () => {
  const store = getStore();
  return store.sections.default;
};

const isAuthorizedSection = (to) => {
  const allowedSections = getAllowedSections();
  const found = to.matched.some((record) => record.path in allowedSections);
  return !(!found && to.fullPath !== '/settings');
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/auth',
      name: 'auth',
      component: Authentication,
      children: [
        {
          path: 'login',
          name: 'login',
          component: Login,
        },
        {
          path: 'forgot-password',
          name: 'forgot',
          component: () => import('./components/authentication/ForgotPassword.vue'),
        },
        {
          path: 'reset-password',
          name: 'reset',
          component: () => import('./components/authentication/ResetPassword.vue'),
        },
        {
          path: 'send-invite',
          name: 'invite',
          component: () => import('./components/authentication/AccountInvite.vue'),
        },
      ],
    },
    {
      path: '/',
      beforeEnter(to, from, next) {
        if (to.path === '/') {
          next({ path: getDefaultSection() });
        } else {
          next();
        }
      },
      name: 'dashboard',
      component: Dashboard,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'settings',
          component: Settings,
          meta: {
            breadcrumb: 'Account Settings',
            requiresAuth: true,
          },
        },
        {
          path: 'invoices',
          component: () => import('./components/general/RouterIndex.vue'),
          meta: {
            breadcrumb: 'My Invoices',
            requiresAuth: true,
          },
          children: [
            {
              path: '/',
              component: () => import('./components/invoices/InvoiceList.vue'),
              meta: {
                requiresAuth: true,
                actions: [
                  {
                    type: 'dropdown',
                    text: 'New Invoice',
                    authorized: [VENDOR_EXTERNAL, RECEPCION_MOS],
                    subactions: [
                      {
                        icon: 'fas fa-edit',
                        text: 'Create Manually',
                        subtitle: 'If your invoice is handwritten, not eligible or with insufficient data, please click on this option and fill out the required fields, otherwise your invoice will not be accepted',
                        link: 'create',
                      },
                      {
                        icon: 'fas fa-file-upload',
                        text: 'Upload Digital File',
                        link: 'upload',
                      },
                    ],
                  },
                  {
                    type: "button",
                    text: "Mass Create Invoices",
                    link: "mass-create",
                  }
                ],
              },
            },
            {
              path: 'mass-create',
              component: () => import('./components/invoices/InvoiceMassCreate.vue'),
              meta: {
                breadcrumb: 'Create',
                requiresAuth: true,
              },
            },
            {
              path: 'create',
              component: () => import('./components/invoices/InvoiceCreate.vue'),
              meta: {
                breadcrumb: 'Create',
                requiresAuth: true,
              },
            },
            {
              path: 'upload',
              component: () => import('./components/invoices/InvoiceUpload.vue'),
              meta: {
                breadcrumb: 'Upload',
                requiresAuth: true,
              },
            },
            {
              path: ':invoiceId',
              component: () => import('./components/invoices/InvoiceView.vue'),
              meta: {
                breadcrumb: 'Invoice',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: 'inquiries',
          component: () => import('./components/general/RouterIndex.vue'),
          meta: {
            breadcrumb: 'Inquiries',
            requiresAuth: true,
          },
          children: [
            {
              path: '/',
              component: () => import('./components/inquiries/InquiryList.vue'),
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: 'vendors',
          component: () => import('./components/general/RouterIndex.vue'),
          meta: {
            breadcrumb: 'Vendors',
            requiresAuth: true,
            actions: [
              {
                type: 'button',
                icon: 'fas fa-plus',
                text: 'New Vendor',
                link: 'create',
              },
            ],
          },
          children: [
            {
              path: '/',
              component: () => import('./components/vendors/VendorList.vue'),
              meta: {
                breadcrumb: 'Vendors',
                requiresAuth: true,
                actions: [
                  {
                    type: 'button',
                    icon: 'fas fa-plus',
                    text: 'New Vendor',
                    link: 'create',
                  },
                  {
                    type: 'button',
                    icon: 'fas fa-plus',
                    text: 'Mass Create Vendors',
                    link: 'mass-create',
                  },
                ],
              },
            },
            {
              path: 'create',
              component: () => import('./components/vendors/VendorCreate.vue'),
              meta: {
                breadcrumb: 'Create',
                requiresAuth: true,
              },
            },
            {
              path: 'mass-create',
              component: () => import('./components/vendors/VendorMassCreate.vue'),
              meta: {
                breadcrumb: 'Create',
                requiresAuth: true,
              },
            },
            {
              path: ':vendorId',
              component: () => import('./components/vendors/VendorView.vue'),
              meta: {
                breadcrumb: 'Vendor',
                requiresAuth: true,
              },
            },
            {
              path: ':vendorId/edit',
              component: () => import('./components/vendors/VendorUpdate.vue'),
              meta: {
                breadcrumb: 'Update',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: 'notifications',
          component: () => import('./components/general/RouterIndex.vue'),
          meta: {
            breadcrumb: 'Notifications',
            requiresAuth: true,
            actions: [],
          },
          children: [
            {
              path: '/',
              component: () => import('./components/notifications/NotificationDashboard.vue'),
              meta: {
                breadcrumb: 'Notifications Dashboard',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: 'property-managers',
          component: () => import('./components/general/RouterIndex.vue'),
          meta: {
            breadcrumb: 'Property Managers',
            requiresAuth: true,
            actions: [],
          },
          children: [
            {
              path: '/',
              component: () => import('./components/property-managers/PropertyManagerList.vue'),
              meta: {
                breadcrumb: 'Property Managers',
                requiresAuth: true,
                actions: [
                  {
                    type: 'button',
                    icon: 'fas fa-plus',
                    text: 'New Property Manager',
                    link: 'create',
                  },
                  {
                    type: 'button',
                    icon: 'fas fa-plus',
                    text: 'Mass Create Property Managers',
                    link: 'mass-create',
                  },
                ],
              },
            },
            {
              path: 'create',
              component: () => import('./components/property-managers/PropertyManagerCreate.vue'),
              meta: {
                breadcrumb: 'New Property Manager',
                requiresAuth: true,
              },
            },
            {
              path: 'mass-create',
              component: () => import('./components/property-managers/PropertyManagerMassCreate.vue'),
              meta: {
                breadcrumb: 'Create',
                requiresAuth: true,
              },
            },
            {
              path: ':propertyManagerId',
              component: () => import('./components/property-managers/PropertyManagerView.vue'),
              meta: {
                breadcrumb: 'Property Manager',
                requiresAuth: true,
              },
            },
            {
              path: ':propertyManagerId/edit',
              component: () => import('./components/property-managers/PropertyManagerUpdate.vue'),
              meta: {
                breadcrumb: 'Edit',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: 'properties',
          component: () => import('./components/general/RouterIndex.vue'),
          meta: {
            breadcrumb: 'My Properties',
            requiresAuth: true,
          },
          children: [
            {
              path: '/',
              component: () => import('./components/properties/PropertyList.vue'),
              meta: {
                requiresAuth: true,
                actions: [
                  {
                    authorized: [
                      ADMIN_MOS,
                      COORDINADOR_MOS,
                      PROCESAMIENTO_MOS,
                      RECEPCION_MOS,
                      ADMIN_CLIENTE,
                      STAFF_CLIENT,
                    ],
                    type: 'button',
                    icon: 'fas fa-plus',
                    text: 'New Property',
                    link: 'create',
                  },
                  {
                    authorized: [
                      ADMIN_MOS,
                      COORDINADOR_MOS,
                      PROCESAMIENTO_MOS,
                      RECEPCION_MOS,
                      ADMIN_CLIENTE,
                      STAFF_CLIENT,
                    ],
                    type: 'button',
                    icon: 'fas fa-plus',
                    text: 'Mass Create Properties',
                    link: 'mass-create',
                  },
                ],
              },
            },
            {
              path: 'create',
              component: () => import('./components/properties/PropertyCreate.vue'),
              meta: {
                breadcrumb: 'Create',
                requiresAuth: true,
              },
            },
            {
              path: 'mass-create',
              component: () => import('./components/properties/PropertyMassCreate.vue'),
              meta: {
                breadcrumb: 'Create',
                requiresAuth: true,
              },
            },
            {
              path: ':propertyId',
              component: () => import('./components/properties/PropertyView.vue'),
              meta: {
                breadcrumb: 'Invoice',
                requiresAuth: true,
              },
            },
            {
              path: ':propertyId/edit',
              component: () => import('./components/properties/PropertyUpdate.vue'),
              meta: {
                breadcrumb: 'Edit',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: 'utilities',
          component: () => import('./components/general/RouterIndex.vue'),
          redirect: 'utilities/providers',
          meta: {
            breadcrumb: 'Utilities',
            requiresAuth: true,
          },
          children: [
            {
              path: 'providers',
              component: () => import('./components/general/RouterIndex.vue'),
              meta: {
                requiresAuth: true,
                breadcrumb: 'Providers',
              },
              children: [
                {
                  path: '/',
                  component: () => import('./components/utilities/providers/ProviderList.vue'),
                  meta: {
                    actions: [
                      {
                        type: 'button',
                        icon: 'fas fa-plus',
                        text: 'New Provider',
                        link: 'create',
                      },
                      {
                        type: 'button',
                        icon: 'fas fa-plus',
                        text: 'Mass Create Providers',
                        link: 'mass-create',
                      },
                    ],
                  },
                },
                {
                  path: 'mass-create',
                  component: () => import('./components/utilities/providers/ProviderMassCreate.vue'),
                  meta: {
                    breadcrumb: 'Create',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'create',
                  component: () => import('./components/utilities/providers/ProviderCreate.vue'),
                  meta: {
                    breadcrumb: 'Create',
                    requiresAuth: true,
                  },
                },
                {
                  path: ':utilityProviderId',
                  component: () => import('./components/utilities/providers/ProviderView.vue'),
                  meta: {
                    breadcrumb: 'View',
                    requiresAuth: true,
                  },
                },
                {
                  path: ':utilityProviderId/edit',
                  component: () => import('./components/utilities/providers/ProviderUpdate.vue'),
                  meta: {
                    breadcrumb: 'Edit',
                    requiresAuth: true,
                  },
                },
              ],
            },
            {
              path: 'accounts',
              component: () => import('./components/general/RouterIndex.vue'),
              meta: {
                requiresAuth: true,
                breadcrumb: 'Accounts',
              },
              children: [
                {
                  path: '/',
                  component: () => import('./components/utilities/accounts/AccountList.vue'),
                  meta: {
                    requiresAuth: true,
                    breadcrumb: 'Accounts',
                    actions: [
                      {
                        type: 'button',
                        icon: 'fas fa-plus',
                        text: 'New Account',
                        link: 'create',
                      },
                      {
                        type: 'button',
                        icon: 'fas fa-plus',
                        text: 'Mass Create Accounts',
                        link: 'mass-create',
                      },
                    ],
                  },
                },
                {
                  path: 'create',
                  component: () => import('./components/utilities/accounts/AccountCreate.vue'),
                  meta: {
                    breadcrumb: 'Create',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'mass-create',
                  component: () => import('./components/utilities/accounts/AccountMassCreate.vue'),
                  meta: {
                    breadcrumb: 'Create',
                    requiresAuth: true,
                  },
                },
                {
                  path: ':accountId',
                  component: () => import('./components/utilities/accounts/AccountView.vue'),
                  meta: {
                    breadcrumb: 'View',
                    requiresAuth: true,
                  },
                },
                {
                  path: ':utilityAccountId/edit',
                  component: () => import('./components/utilities/accounts/AccountUpdate.vue'),
                  meta: {
                    breadcrumb: 'Edit',
                    requiresAuth: true,
                  },
                },
              ],
            },
            {
              path: 'invoices',
              component: () => import('./components/general/RouterIndex.vue'),
              meta: {
                breadcrumb: 'Invoices',
                requiresAuth: true,
              },
              children: [
                {
                  path: '/',
                  component: () => import('./components/utilities/invoices/InvoiceList.vue'),
                  meta: {
                    breadcrumb: 'Invoices',
                    requiresAuth: true,
                    actions: [
                      {
                        type: 'button',
                        icon: 'fas fa-plus',
                        text: 'Mass Create Invoices',
                        link: '/mass-create',
                      },
                    ],
                  },
                },
                {
                  path: '/mass-create',
                  component: () => import('./components/utilities/invoices/InvoiceMassCreate.vue'),
                  meta: {
                    breadcrumb: 'Create',
                    requiresAuth: true,
                  },
                },
                {
                  path: ':invoiceId',
                  component: () => import('./components/utilities/invoices/InvoiceView.vue'),
                  meta: {
                    breadcrumb: 'Invoice',
                    requiresAuth: true,
                  },
                },
                {
                  path: ':invoiceId/edit',
                  component: () => import('./components/utilities/invoices/InvoiceUpdate.vue'),
                  meta: {
                    breadcrumb: 'Invoice',
                    requiresAuth: true,
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'companies',
          component: () => import('./components/general/RouterIndex.vue'),
          meta: {
            breadcrumb: 'Companies',
            requiresAuth: true,
            actions: [],
          },
          children: [
            {
              path: '/',
              component: () => import('./components/companies/CompanyList.vue'),
              meta: {
                breadcrumb: 'Companies',
                requiresAuth: true,
                actions: [
                  {
                    type: 'button',
                    icon: 'fas fa-plus',
                    text: 'New Company',
                    link: 'create',
                  },
                  {
                    type: 'button',
                    icon: 'fas fa-plus',
                    text: 'Mass Create Companies',
                    link: 'mass-create',
                  },
                ],
              },
            },
            {
              path: 'create',
              component: () => import('./components/companies/CompanyCreate.vue'),
              meta: {
                breadcrumb: 'New Company',
                requiresAuth: true,
              },
            },
            {
              path: 'mass-create',
              component: () => import('./components/companies/CompanyMassCreate.vue'),
              meta: {
                breadcrumb: 'Create',
                requiresAuth: true,
              },
            },
            {
              path: ':companyId',
              component: () => import('./components/companies/CompanyView.vue'),
              meta: {
                breadcrumb: 'Company',
                requiresAuth: true,
              },
            },
            {
              path: ':companyId/edit',
              component: () => import('./components/companies/CompanyUpdate.vue'),
              meta: {
                breadcrumb: 'Edit',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: 'users',
          component: () => import('./components/general/RouterIndex.vue'),
          meta: {
            breadcrumb: 'Users',
            requiresAuth: true,
          },
          children: [
            {
              path: '/',
              component: () => import('./components/users/UserList.vue'),
              meta: {
                requiresAuth: true,
                actions: [
                  {
                    type: 'button',
                    icon: 'fas fa-plus',
                    text: 'New User',
                    link: 'create',
                  },
                  {
                    type: 'button',
                    icon: 'fas fa-plus',
                    text: 'Mass Create Users',
                    link: 'mass-create',
                  },
                ],
              },
            },
            {
              path: 'create',
              component: () => import('./components/users/UserCreate.vue'),
              meta: {
                breadcrumb: 'Create',
                requiresAuth: true,
              },
            },
            {
              path: 'mass-create',
              component: () => import('./components/users/UserMassCreate.vue'),
              meta: {
                breadcrumb: 'Create',
                requiresAuth: true,
              },
            },
            {
              path: ':userId',
              component: () => import('./components/users/UserView.vue'),
              meta: {
                breadcrumb: 'Invoice',
                requiresAuth: true,
              },
            },
            {
              path: ':userId/edit',
              component: () => import('./components/users/UserUpdate.vue'),
              meta: {
                breadcrumb: 'Edit',
                requiresAuth: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: '*',
      component: () => import('./components/NotFound.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const hasAuthToken = !!localStorage.getItem('token');

    if (!hasAuthToken) {
      next({
        path: '/auth/login',
        params: { nextUrl: to.fullPath },
      });
    } else {
      if (!isAuthorizedSection(to)) {
        next({ path: getDefaultSection() });
      }
      next();
    }
  } else {
    next();
  }
});

export default router;
