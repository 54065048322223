<template>
  <v-snackbar
    v-model="displayNotification"
    :color="notification.type"
    :timeout="notification.timeout"
    :top="notification.position === 'top'"
    :bottom="notification.position !== 'top'"
  >
    {{ notification.message }}
    <v-btn
      icon
      text
      @click="displayNotification = false"
    >
      <v-icon small>
        fas fa-times
      </v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'DisplayNotification',
  computed: {
    ...mapState(['notification']),
    displayNotification: {
      get() {
        return this.notification.display;
      },
      set() {
        this.clearNotification();
      },
    },
  },
  methods: {
    ...mapActions(['clearNotification']),
  },
};
</script>
