import { repositoryFactory } from '@/repositories/repositoryFactory';

const invoiceRepository = repositoryFactory.invoice;
const fileRepository = repositoryFactory.file;

const buildInvoiceFilePayload = (id, file) => ({
  files: file,
  refId: id,
  ref: 'invoice',
  field: 'image',
});

export default {
  async fetchAllInvoices({ commit }, query) {
    const invoices = await invoiceRepository.getAll(query);
    commit('fetchInvoicesSucceeded', { invoices });
  },
  async createInvoice({ commit }, { invoice, file }) {
    commit('startLoading');
    const createdInvoice = await invoiceRepository.create({
      invoice,
      file
    });
    commit('createInvoiceSuccess');
    commit('stopLoading');
    return createdInvoice;
  },
  async massCreateInvoice({ commit }, { data, files }) {
    commit('startLoading');
    const response = await invoiceRepository.massCreateInvoice({data, files,});
    commit('createInvoiceSucceeded');
    commit('stopLoading');
    return response;
  },
  validateInvoiceFailed({ commit }) {
    commit('validateInvoiceFailed');
  },
  async fetchInvoice({ commit }, { id }) {
    commit('startLoading');
    const data = await invoiceRepository.get(id);
    commit('fetchInvoiceSucceeded', data);
    commit('stopLoading');
  },
  async updateInvoiceStatus({ commit }, { invoiceId, statusId }) {
    commit('startLoading');
    await invoiceRepository.update(invoiceId, statusId);
    commit('stopLoading');
  },
  async updateInvoice({ commit }, { invoiceId, ...payload }) {
    commit('startLoading');
    await invoiceRepository.update(invoiceId, payload);
    commit('updateInvoiceSuccess');
    commit('stopLoading');
  },
  async approveInvoice({ commit }, { id }) {
    commit('startLoading');
    await invoiceRepository.approve(id);
    commit('stopLoading');
  },
  async rejectInvoice({ commit }, { id, message }) {
    commit('startLoading');
    await invoiceRepository.reject(id, { rejectionMessage: message });
    commit('stopLoading');
  },
  async deleteInvoiceById({ commit }, invoiceId) {
    commit('startLoading');
    await invoiceRepository.delete(invoiceId);
    commit('deleteInvoiceSucceeded');
    commit('stopLoading');
  },
};
