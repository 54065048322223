export default {
  deleteVendorSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The vendor has been deleted successfully!',
      timeout: 2000,
    };
  },
  deleteVendorFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an issue deleting the vendor!',
      timeout: 2000,
    };
  },
  createVendorSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The vendor has been created successfully!',
      timeout: 2000,
    };
  },
  createVendorFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an issue creating the vendor!',
      timeout: 2000,
    };
  },
  fetchAllVendorsSucceeded(state, { vendors }) {
    state.vendors = vendors;
  },
  updateVendorSucceeded(state, { vendor }) {
    state.vendor = vendor;
    state.notification = {
      display: true,
      type: 'success',
      message: 'The settings have been updated successfully!',
      timeout: 2000,
    };
  },
  updateVendorFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an issue updating your settings!',
      timeout: 2000,
    };
  },
  fetchVendorSucceeded(state, { vendor }) {
    state.vendor = vendor;
  },
  fetchVendorFailed(state) {
    state.vendor = null;
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error loading the vendor information.',
      timeout: 3000,
    };
  },
};
