export default {
  createUtilityTypeSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The utility has been created successfully!',
      timeout: 2000,
    };
  },
  createUtilityTypeFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error creating the Utility.',
      timeout: 3000,
    };
  },
  updateUtilityTypeSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The utility has been updated successfully!',
      timeout: 2000,
    };
  },
  updateUtilityTypeFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error updating the Utility.',
      timeout: 3000,
    };
  },
  validateUtilityTypeFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'Please complete all required fields',
      timeout: 3000,
    };
  },
  fetchAllUtilityTypesSucceeded(state, { utilityTypes }) {
    state.utilityTypes = utilityTypes;
  },
  fetchAllUtilityTypesFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error fetching the Utilities.',
      timeout: 3000,
    };
  },
  fetchUtilityTypeByIdSucceeded(state, { utilityType }) {
    state.utilityType = utilityType;
  },
  fetchUtilityTypeByIdFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error fetching the Utilities.',
      timeout: 3000,
    };
  },
  deleteUtilityTypeByIdSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The utility has been deleted successfully!',
      timeout: 2000,
    };
  },
  deleteUtilityTypeByIdFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error deleting the Utility.',
      timeout: 3000,
    };
  },
};
