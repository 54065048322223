import vendorActions from '@/store/actions/vendor';
import userActions from '@/store/actions/user';
import invoiceActions from '@/store/actions/invoice';
import inquiryActions from '@/store/actions/inquiry';
import propertyActions from '@/store/actions/property';
import utilityActions from '@/store/actions/utility';
import roleActions from '@/store/actions/role';
import propertyManagerActions from '@/store/actions/propertyManager';
import authenticationActions from '@/store/actions/authentication';
import companyActions from '@/store/actions/company';
import authorizationActions from '@/store/actions/authorization';
import notificationActions from '@/store/actions/notification';
import confirmationDialogActions from '@/store/actions/confirmationDialog';
import settingsActions from '@/store/actions/settings';
import notificationsActions from '@/store/actions/notifications';
import approvalActions from '@/store/actions/approval';
import utilityProvider from '@/store/actions/utilityProvider';
import utilityAccount from '@/store/actions/utilityAccount';
import utilityInvoice from '@/store/actions/utilityInvoice';
import utilityType from '@/store/actions/utilityType';

export default {
  ...utilityType,
  ...utilityInvoice,
  ...utilityAccount,
  ...utilityProvider,
  ...approvalActions,
  ...vendorActions,
  ...userActions,
  ...roleActions,
  ...invoiceActions,
  ...inquiryActions,
  ...propertyActions,
  ...utilityActions,
  ...propertyManagerActions,
  ...authenticationActions,
  ...notificationsActions,
  ...companyActions,
  ...authorizationActions,
  ...notificationActions,
  ...confirmationDialogActions,
  ...settingsActions,
};
