import { repositoryFactory } from '@/repositories/repositoryFactory';

const companyRepository = repositoryFactory.company;

export default {
  async fetchAllCompanies({ commit }) {
    commit('startLoading');
    const companies = await companyRepository.getAll();
    commit('fetchAllCompaniesSucceeded', { companies });
    commit('stopLoading');
  },

  async fetchCompanyByID({ commit }, { id }) {
    commit('startLoading');
    const company = await companyRepository.get(id);
    commit('fetchCompanyByIDSucceeded', { company });
    commit('stopLoading');
  },

  async deleteCompanyByID({ commit }, { id }) {
    commit('startLoading');
    await companyRepository.delete(id);
    commit('deleteCompanyByIDSucceeded');
    commit('stopLoading');
  },

  async updateCompany({ commit }, { company }) {
    commit('startLoading');
    await companyRepository.update(company.id, company);
    commit('updateCompanySucceeded');
    commit('stopLoading');
  },

  async createCompany({ commit }, { company }) {
    commit('startLoading');
    await companyRepository.create(company);
    commit('createCompanySucceeded', { company });
    commit('stopLoading');
  },

  async massCreateCompany({ commit }, { companies }) {
    commit('startLoading');
    // eslint-disable-next-line no-console
    await companyRepository.massCreate(companies);
    commit('createCompanySucceeded', { companies });
    commit('stopLoading');
  },
};
