import { repositoryFactory } from '@/repositories/repositoryFactory';

const utilityProviderRepository = repositoryFactory.utilityProvider;

export default {
  async fetchAllUtilityProviders({ commit }, query) {
    commit('startLoading');
    const utilityProviders = await utilityProviderRepository.getAll(query);
    commit('fetchAllUtilityProvidersSucceeded', { utilityProviders });
    commit('stopLoading');
  },
  async fetchUtilityProviderById({ commit }, { id }, store) {
    commit('startLoading');
    const utilityProvider = await utilityProviderRepository.get(id);
    if (store) {
      commit('fetchUtilityProviderByIdSucceeded', { utilityProvider });
    }
    commit('stopLoading');
    return utilityProvider;
  },
  async deleteUtilityProviderById({ commit }, { id }) {
    await utilityProviderRepository.delete(id);
    commit('deleteUtilityProviderSucceeded');
  },
  async massCreateUtilityProvider({ commit }, { utilityProviders }) {
    commit('startLoading');
    let response = await utilityProviderRepository.massCreate(utilityProviders);
    commit('createUtilityProviderSucceeded', { utilityProviders });
    commit('stopLoading');
    return response;
  },
  async createUtilityProvider({ commit }, { utilityProvider }) {
    commit('startLoading');
    let response = await utilityProviderRepository.create(utilityProvider);
    commit('createUtilityProviderSucceeded');
    commit('stopLoading');
    return response;
  },
  async updateUtilityProvider({ commit }, { utilityProvider }) {
    commit('startLoading');
    await utilityProviderRepository.update(utilityProvider.id, utilityProvider);
    commit('updateUtilityProviderSucceeded');
    commit('stopLoading');
  },
};
