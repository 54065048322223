<template>
  <div
    class="card-component"
    :style="borderColor"
  >
    <v-container
      fluid
      grid-list-lg
      v-bind="$attrs"
    >
      <slot />
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'CardTemplate',
  props: {
    color: { type: String, default: '' },
  },
  computed: {
    borderColor() {
      return `border-top-color: var(--v-${this.color || 'primary'}-base)`;
    },
  },
};
</script>

<style lang="scss">
@import "../../../scss/_colors.scss";

.card-component {
  border: 1px solid $borderLightGrey;
  border-top: 5px solid;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 3em;
}
</style>
