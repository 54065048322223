import { repositoryFactory } from '@/repositories/repositoryFactory';

const userRepository = repositoryFactory.user;
const propertyManagerRepository = repositoryFactory.propertyManager;

export default {
  async fetchAllPropertyManagers({ commit }, query) {
    commit('startLoading');
    const propertyManagers = await propertyManagerRepository.getAll(query);
    commit('fetchAllPropertyManagersSucceeded', { propertyManagers });
    commit('stopLoading');
  },
  async fetchPropertyManagersByCompanyId({ commit }, { id }) {
    commit('startLoading');
    const propertyManagers = await propertyManagerRepository.getAll({ company: id });
    commit('fetchAllPropertyManagersSucceeded', { propertyManagers });
    commit('stopLoading');
  },
  async fetchOwnPropertyManager({ commit, state }) {
    commit('startLoading');
    const propertyManager = await propertyManagerRepository.getAll({ user: state.user.id });
    commit('fetchOwnPropertyManagerSucceeded', { propertyManager: propertyManager[0] });
    commit('stopLoading');
  },
  async fetchPropertyManagerByID({ commit }, { id }) {
    commit('startLoading');
    const propertyManager = await propertyManagerRepository.get(id);
    commit('fetchPropertyManagerByIDSucceeded', { propertyManager });
    commit('stopLoading');
  },
  async deletePropertyManager({ commit }, { propertyManager }) {
    commit('startLoading');
    await propertyManagerRepository.delete(propertyManager.id);
    await userRepository.delete(propertyManager.user.id);
    commit('deletePropertyManagerSucceeded');
    commit('stopLoading');
  },
  async createPropertyManager({ commit, dispatch }, { propertyManager }) {
    const PROPERTY_MANAGER_ROLE = 10;

    const user = (({
      company, contactFirstName, contactLastName, contactMiddleName, email,
    }) => ({
      firstname: contactFirstName,
      middlename: contactMiddleName,
      lastname: contactLastName,
      username: email,
      company,
      email,
      password: Math.random().toString(36).slice(-8),
      role: PROPERTY_MANAGER_ROLE,
    }))(propertyManager);
    commit('startLoading');
    const registeredUser = await userRepository.create(user);
    dispatch('sendInvite', registeredUser.email);
    await propertyManagerRepository.create({ ...propertyManager, user: registeredUser.id });
    commit('createPropertyManagerSucceeded');
    commit('stopLoading');
  },
  validatePropertyManagerFailed({ commit }) {
    commit('displayNotification', {
      type: 'error',
      message: 'Please complete all necessary fields to continue.',
    });
  },
  async massCreatePropertyManager({ commit }, { propertyManagers }) {
    commit('startLoading');
    // eslint-disable-next-line no-console
    await propertyManagerRepository.massCreate(propertyManagers);
    commit('createCompanySucceeded', { propertyManagers });
    commit('stopLoading');
  },
  async updatePropertyManager({ commit }, { propertyManager }) {
    commit('startLoading');
    const updatedPropertyManager = await propertyManagerRepository
      .update(propertyManager.id, propertyManager);
    commit('updatePropertyManagerSucceeded', { propertyManager: updatedPropertyManager });
    commit('stopLoading');
  },
};
