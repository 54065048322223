<template>
  <div class="mb-4">
    <span class="headline font-weight-medium"> {{ title }} </span>
  </div>
</template>

<script>
export default {
  name: 'CardSectionTitle',
  props: {
    title: { type: String, default: '' },
  },
};
</script>
