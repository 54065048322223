<template>
  <div>
    <v-card
      id="menu_button"
      v-model="showSidebar"
    >
      <v-app-bar-nav-icon
        @click.stop="showSidebar = !showSidebar"
      />
    </v-card>
    <v-navigation-drawer
      v-model="showSidebar"
      :fixed="isMobile"
      :temporary="isMobile"
      :permanent="!isMobile"
      color="bgDarkGrey"
      class="nav-drawer"
      app
    >
      <div
        class="elevation-5 pa-2"
      >
        <v-img
          :src="require('@/assets/logo-mos.svg')"
          aspect-ratio="1.3"
        />
      </div>
      <v-list dense>
        <div
          v-for="item in authorizedSections"
          :key="item.title"
        >
          <v-list-item
            v-if="item.type === 'link'"
            v-model="item.active"
            link
            active-class="sidebar-link"
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="d-flex justify-start">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-if="item.type === 'nested'"
            :value="false"
            :prepend-icon="item.icon"
            color="btnDarkGrey"
          >
            <template #activator>
              <v-list-item-title class="d-flex justify-start">
                {{ item.title }}
              </v-list-item-title>
            </template>

            <v-list-item
              v-for="nestedItem in item.nestedItems"
              :key="nestedItem.title"
              v-model="nestedItem.active"
              :to="nestedItem.link"
              class="sidebar-nested"
              active-class="sidebar-link"
            >
              <v-list-item-icon>
                <v-icon v-text="nestedItem.icon" />
              </v-list-item-icon>
              <v-list-item-title
                class="d-flex justify-start"
                v-text="nestedItem.title"
              />
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item
          v-if="isVendor"
          to="/settings"
          active-class="sidebar__selected"
        >
          <v-list-item-action>
            <v-icon>far fa-user</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Account Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logoutClick">
          <v-list-item-action>
            <v-icon>fas fa-sign-out-alt</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="isEmployee"
          class="sidebar-companies"
        >
          <v-select
            v-model="selectedCompany"
            single-line
            clearable
            label="Company selection"
            item-text="name"
            item-value="id"
            :items="companies"
          />
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { employeeRoles, VENDOR_EXTERNAL } from '../constants';

export default {
  name: 'ApplicationSidebar',
  data: () => ({
    showSidebar: false,
  }),
  computed: {
    ...mapState(['role']),
    isEmployee() {
      return Object.values(employeeRoles).includes(this.role.type);
    },
    isVendor() {
      const { role } = this.$store.state;
      return role && role.type === VENDOR_EXTERNAL;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    companies() {
      return this.$store.state.companies;
    },
    authorizedSections() {
      return this.$store.state.sections.items || [];
    },
    defaultRoute() {
      return this.$store.state.sections.default;
    },
    currentRoute() {
      return this.$route.path;
    },
    selectedCompany: {
      get() {
        return this.$store.state.settings.company;
      },
      set(company) {
        this.$store.dispatch('selectCompany', { company });
        this.goToHome();
      },
    },
  },
  beforeMount() {
    this.fetchCompanies();
  },
  methods: {
    ...mapActions(['logout', 'fetchAllCompanies']),
    logoutClick() {
      this.logout();
      this.$router.go();
    },
    fetchCompanies() {
      if (this.isEmployee) {
        this.$store.dispatch('fetchAllCompanies');
      }
    },
    goToHome() {
      if (this.currentRoute === this.defaultRoute) {
        this.$router.go();
      }
      this.$router.replace({ path: this.defaultRoute });
    },
  },
};
</script>

<style lang="scss">
@import '../scss/_colors.scss';

#menu_button {
  z-index: 2;
  border-radius: 0 0 10px 0;
  padding: 10px;
  display: inline-block;
  position: fixed;
  top: 0;
  left: 0;
}
.nav-drawer{
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}

.nav-drawer::-webkit-scrollbar{
  display: none;
}

.sidebar-link{
  color: $primary!important;
}

.sidebar {
  &__selected {
    color: $primary !important;
    background-color: #ebeced;
    border-left: 4px solid $primary !important;

    &::before {
      color: #ffffff;
    }

    &:hover {
      background-color: $bgLightGrey !important;
    }
  }

  &__nested__selected {
    border-top: #6c6c6c 2px !important;
    padding-left: 0;

    &::before {
      color: $bgDarkGrey;
    }
  }

  &-companies {
    position: fixed;
    width: 100%;
    bottom: 0;
  }

  &-nested {
    background-color: #fff;
  }
}
</style>
