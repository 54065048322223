import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import './plugins/lodash';
import MultiFiltersPlugin from './plugins/multiFilters';
import './plugins/htmlToPaper';
import filters from '@/filters';
import MSDutilities from '@/plugins/MSDutilities';
import App from './App.vue';
import router from './router';
import store from './store';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import i18n from './plugins/i18n';

Vue.config.productionTip = false;
Vue.use(MSDutilities);
Vue.use(MultiFiltersPlugin);

new Vue({
  MSDutilities,
  filters,
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
