export default {
  displayConfirmationDialog({ commit }, { nextUrl }) {
    commit('displayConfirmationDialog', { nextUrl });
  },
  stayConfirmationDialog({ commit }) {
    commit('stayConfirmationDialog');
  },
  leaveConfirmationDialog({ commit }) {
    commit('leaveConfirmationDialog');
  },
  resetConfirmation({ commit }) {
    commit('resetConfirmation');
  },
};
