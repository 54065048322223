<template>
  <v-dialog
    v-model="dialog"
    eager
    scrollable
    width="700"
  >
    <template #activator="{on}">
      <v-btn
        outlined
        :color="btnColor"
        class="text-capitalize"
        v-on="on"
      >
        <v-icon
          small
          left
        >
          {{ btnIcon }}
        </v-icon> {{ btnText }}
      </v-btn>
    </template>

    <v-card>
      <v-card-text>
        <iframe
          title=""
          :src="src"
          style="width:90%; height:700px;"
          frameborder="0"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FileViewer',
  props: {
    btnText: { type: String, default: '' },
    btnIcon: { type: String, default: '' },
    btnColor: { type: String, default: '' },
    styleClass: { type: String, default: '' },
    src: { type: String, default: '' },
  },
  data: () => ({
    dialog: false,
  }),
};
</script>
