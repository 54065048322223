// Roles
export const ADMIN_MOS = 'administrador_mos';
export const COORDINADOR_MOS = 'coordinador_mos';
export const PROCESAMIENTO_MOS = 'procesamiento_mos';
export const RECEPCION_MOS = 'recepcion_mos';
export const ADMIN_CLIENTE = 'administrador_cliente';
export const PROPERTY_MANAGER_CLIENTE = 'property_manager_cliente';
export const STAFF_CLIENT = 'staff_cliente';
export const VENDOR_EXTERNAL = 'vendor';

export const roleNames = {
  8: 'administrador_mos',
  4: 'coordinador_mos',
  7: 'procesamiento_mos',
  6: 'recepcion_mos',
  11: 'administrador_cliente',
  10: 'property_manager_cliente',
  9: 'staff_cliente',
  12: 'vendor',
};

export const updatePropertyPermission = {
  4: 'coordinador_mos',
  9: 'staff_cliente',
  8: 'administrador_mos',
};

export const employeeRoles = {
  admin: ADMIN_MOS,
  coordinator: COORDINADOR_MOS,
  processing: PROCESAMIENTO_MOS,
  reception: RECEPCION_MOS,
};

export const clientRoles = {
  admin: ADMIN_CLIENTE,
  propertyManager: PROPERTY_MANAGER_CLIENTE,
  staff: STAFF_CLIENT,
};

export const externalRoles = {
  vendor: VENDOR_EXTERNAL,
};

export const roles = {
  employees: employeeRoles,
  clients: clientRoles,
  external: externalRoles,
};

// Dashboard sections
export const COMPANIES_SECTION = {
  title: 'Companies',
  link: '/companies',
  icon: 'fas fa-building',
  type: 'link',
};

export const VENDORS_SECTION = {
  title: 'Vendors',
  link: '/vendors',
  icon: 'fas fa-paint-roller',
  type: 'link',
};

export const PROPERTY_MANAGERS_SECTION = {
  title: 'Property Managers',
  link: '/property-managers',
  icon: 'fas fa-key',
  type: 'link',
};

export const INVOICES_SECTION = {
  title: 'Invoices',
  icon: 'fas fa-receipt',
  type: 'nested',
  nestedItems: [
    {
      title: 'Vendor Invoices',
      link: '/invoices',
      icon: 'fas fa-file-contract',
    },
  ],
};

export const MY_INVOICES_SECTION = {
  title: 'My Invoices',
  link: '/invoices',
  icon: 'fas fa-receipt',
  type: 'link',
};

export const PROPERTIES_SECTION = {
  title: 'Properties',
  link: '/properties',
  icon: 'fas fa-home',
  type: 'link',
};

export const UTILITIES_SECTION = {
  title: 'Utilities',
  type: 'nested',
  icon: 'fas fa-plug',
  nestedItems: [
    {
      title: 'Utility Providers',
      link: '/utilities/providers',
      icon: 'fas fa-user-tie',
    },
    {
      title: 'Utility Accounts',
      link: '/utilities/accounts',
      icon: 'fas fa-user-circle',
    },
    {
      title: 'Utility Invoices',
      link: '/utilities/invoices',
      icon: 'fas fa-file-invoice-dollar',
    },
  ],
};

export const USERS_SECTION = {
  title: 'Users',
  link: '/users',
  icon: 'fas fa-users',
  type: 'link',
};

export const NOTIFICATIONS_SECTION = {
  title: 'Notifications Dashboard',
  link: '/notifications',
  icon: 'fas fa-bell',
  type: 'link',
};

export const INQUIRIES_SECTION = {
  title: 'Inquiries',
  link: '/inquiries',
  icon: 'fas fa-question',
  type: 'link',
};

export const INVOICE_STATUS_REJECTED = 'rejected';
export const invoiceStatus = {
  created: 11,
  pendingApproval: 12,
  approved: 8,
  onSystem: 10,
  rejected: 9,
};

export const inquiryStatus = {
  waitingForResolution: 'waitingForResolution',
  resolved: 'resolved',
  rejected: 'rejected',
};

export const PRIORITY_NORMAL = 'Normal';
export const PRIORITY_URGENT = 'Urgent';

export const invoicePriorities = [
  PRIORITY_NORMAL,
  PRIORITY_URGENT,
];

export const baseURL = process.env.VUE_APP_SERVER_URL;
