export default {
  createInvoiceSuccess(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The invoice has been created successfully!',
      timeout: 2000,
    };
  },
  updateInvoiceSuccess(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The invoice has been updated successfully!',
      timeout: 2000,
    };
  },
  createInvoiceFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an issue creating your invoice!',
      timeout: 2000,
    };
  },
  validateInvoiceFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'Please complete all required fields',
      timeout: 3000,
    };
  },
  fetchInvoicesSucceeded(state, { invoices }) {
    state.invoices = invoices;
  },
  fetchInvoiceSucceeded(state, data) {
    state.invoice = data;
  },
  deleteInvoiceSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The invoice was successfully deleted',
      timeout: 2000,
    };
  },
};
