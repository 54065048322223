<template>
  <v-container
    justify-center
    px-5
  >
    <v-card
      class="pa-5 dropzone-container"
    >
      <label
        for="dz-input"
        class="dz-input-label"
        @drop.prevent="dropHandler"
        @dragover.prevent="()=>{}"
      >
        <input
          id="dz-input"
          type="file"
          :multiple="multiple"
          class="dropzone-file-input"
          @change="input($event)"
        >
        <div class="dropzone">
          <v-img
            contain
            position="bottom"
            height="250px"
            :src="image"
          >
            <p class="dropzone-text">
              Drop your invoices here or click to browse through your local storage.
            </p>
            <div
              v-if="multiple && value.length"
              class="dropzone-files-data"
            >
              <div
                v-for="(file, idx) in value"
                :key="`${file.name}${idx}`"
                class="dz-element-container"
              >
                <span
                  class="dz-element-data"
                >
                  {{ file.name }}
                </span>
                <v-btn
                  small
                  icon
                  text
                  @click.prevent="remove(idx)"
                >
                  <v-icon small>
                    fas fa-times
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <div
              v-else-if="value && value.name"
              class="dropzone-files-data"
            >
              <div
                class="dz-element-container"
              >
                <span
                  class="dz-element-data"
                >
                  {{ value.name }}
                </span>
                <v-btn
                  small
                  icon
                  text
                  @click.prevent="remove()"
                >
                  <v-icon small>
                    fas fa-times
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-img>
        </div>
      </label>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: 'DropzoneTemplate',
  props: {
    btnText: { type: String, default: '' },
    image: { type: String, default: '' },
    multiple: { type: Boolean, default: false },
    value: { type: [Array,File,Object], default: () => [] },
  },
  methods: {
    input(event) {
      if (this.multiple){
        this.$emit('input', [...this.value, ...event.target.files]);
      } else{
        this.$emit('input', event.target.files[0]);
      }
    },
    remove(id) {
      if (id) {
        this.$emit('input', this.value.filter((el, idx) => idx !== id));
      } else {
        this.$emit('input', null);
      }
    },
    dropHandler(ev) {
      let files = [];
      if (ev.dataTransfer.items && this.multiple) {
        [...ev.dataTransfer.items].forEach((item, i) => {
          if (item.kind === 'file') {
            const file = item.getAsFile();
            files.push(file)
          }
        });
        this.$emit('input', [...this.value, ...files]);
      }else {
        this.$emit('input', ev.target.files[0]);
      }
    },
  },
};
</script>


<style lang="scss">
.dropzone{
  border: #c4c4c4 3px solid;
  border-radius: 10px !important;
  font-weight:bold;
}
.dropzone-text{
  color: #6c6c6c!important;
  max-width: 150px;
  margin: 0 auto;
  text-align: center;
}

.dropzone-file-input{
  display: none;
}

.dropzone-files-data{
  padding: 10px;
  max-width: 100%;
  height: 100px;
  overflow-y: scroll;
  z-index: 1001;
}

.dz-element-container{
  max-width: 100%;
  border-bottom: 1px solid #d3d3d3;
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
}

.dz-element-data{
  align-items: center;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis!important;
}

.dz-input-label{
  cursor: pointer;
}
</style>
