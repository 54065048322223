import { repositoryFactory } from '@/repositories/repositoryFactory';

const utilityAccountRepository = repositoryFactory.utilityAccount;

export default {
  async fetchAllUtilityAccounts({ commit }, query) {
    commit('startLoading');
    const utilityAccounts = await utilityAccountRepository.getAll(query);
    commit('fetchAllUtilityAccountsSucceeded', { utilityAccounts });
    commit('stopLoading');
  },
  async fetchUtilityAccountById({ commit }, { id }, store) {
    commit('startLoading');
    const utilityAccount = await utilityAccountRepository.get(id);
    if (store) {
      commit('fetchUtilityAccountByIdSucceeded', { utilityAccount });
    }
    commit('stopLoading');
    return utilityAccount;
  },
  async deleteUtilityAccountById({ commit }, { id }) {
    await utilityAccountRepository.delete(id);
    commit('deleteUtilityAccountSucceeded');
  },
  async createUtilityAccount({ commit }, { utilityAccount }) {
    commit('startLoading');
    await utilityAccountRepository.create(utilityAccount);
    commit('createUtilityAccountSucceeded');
    commit('stopLoading');
  },
  async massCreateUtilityAccount({ commit }, { utilityAccounts }) {
    commit('startLoading');
    let response = await utilityAccountRepository.massCreate(utilityAccounts);
    commit('createUtilityAccountSucceeded', { utilityAccounts });
    commit('stopLoading');
    return response;
  },
  async updateUtilityAccount({ commit }, { utilityAccount }) {
    commit('startLoading');
    await utilityAccountRepository.update(utilityAccount.id, utilityAccount);
    commit('updateUtilityAccountSucceeded');

    commit('stopLoading');
  },
};
