import { repositoryFactory } from '@/repositories/repositoryFactory';

const propertyRepository = repositoryFactory.property;

export default {
  async fetchAllProperties({ commit }, query) {
    commit('startLoading');
    const properties = await propertyRepository.getAll(query);
    commit('fetchAllPropertiesSucceeded', { properties });
    commit('stopLoading');
    return properties;
  },
  async fetchPropertyById({ commit }, { propertyId }) {
    commit('startLoading');
    const property = await propertyRepository.get(propertyId);
    commit('fetchPropertyByIdSucceeded', { property });
    commit('stopLoading');
  },
  async deletePropertyByID({ commit }, { id }) {
    await propertyRepository.delete(id);
    commit('deletePropertySucceeded');
  },
  async createProperty({ commit }, { property }) {
    commit('startLoading');
    await propertyRepository.create(property);
    commit('createPropertySucceeded');
    commit('stopLoading');
  },
  async massCreateProperty({ commit }, { property }) {
    commit('startLoading');
    await propertyRepository.massCreate(property);
    commit('createPropertySucceeded', { property });
    commit('stopLoading');
  },
  async updateProperty({ commit }, { id, property }) {
    console.log(property)
    commit('startLoading');
    await propertyRepository.update(id, property);
    commit('updatePropertySucceeded');
    commit('stopLoading');
  },
};
