export default {
  displayNotification(state, { type, message }) {
    state.notification = {
      display: true,
      type,
      message,
      timeout: 3000,
    };
  },
  clearNotification(state) {
    state.notification = {};
  },
};
