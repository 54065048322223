import { repositoryFactory } from '@/repositories/repositoryFactory';

const inquiryRepository = repositoryFactory.inquiry;

export default {
  async fetchAllInquiries({ commit }, invoiceId) {
    commit('startLoading');
    const inquiries = await inquiryRepository.getAll(invoiceId);
    commit('fetchInquiriesSucceeded', { inquiries });
    commit('stopLoading');
  },
  async createInquiry({ commit }, { inquiry }) {
    commit('startLoading');
    await inquiryRepository.create({ ...inquiry });
    commit('createInquirySuccess');
    commit('stopLoading');
  },
  validateInquiryFailed({ commit }) {
    commit('validateInquiryFailed');
  },
  async fetchInquiry({ commit }, { id }) {
    commit('startLoading');
    const data = await inquiryRepository.get(id);
    commit('fetchInquirySucceeded', data);
    commit('stopLoading');
  },
  async updateInquiry({ commit }, { inquiryId, payload }) {
    commit('startLoading');
    await inquiryRepository.update(inquiryId, payload);
    commit('stopLoading');
  },
  async deleteInquiryById({ commit }, inquiryId) {
    commit('startLoading');
    await inquiryRepository.delete(inquiryId);
    commit('deleteInquirySucceeded');
    commit('stopLoading');
  },
};
