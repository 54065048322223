export default {
  displayNotification({ commit }, { type, message }) {
    commit('displayNotification', {
      type,
      message,
    });
  },
  clearNotification({ commit }) {
    commit('clearNotification');
  },
};
