<template>
  <v-layout
    row
    wrap
    v-bind="$attrs"
  >
    <slot />
  </v-layout>
</template>

<script>
export default {
  name: 'CardTile',
};
</script>
