export default {
  fetchAllRolesSucceeded(state, { roles }) {
    state.roles = roles;
  },
  fetchAllRolesFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an issue fetching the roles!',
      timeout: 2000,
    };
  },
};
