import { repositoryFactory } from '@/repositories/repositoryFactory';

const roleRepository = repositoryFactory.role;

export default {
  async fetchAllRoles({ commit }, query) {
    commit('startLoading');
    const roles = await roleRepository.getAll(query);
    commit('fetchAllRolesSucceeded', roles);
    commit('stopLoading');
  },
};
