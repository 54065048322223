export default {
  createUtilitySucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The utility has been created successfully!',
      timeout: 2000,
    };
  },
  createUtilityFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error creating the Utility.',
      timeout: 3000,
    };
  },
  updateUtilitySucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The utility has been updated successfully!',
      timeout: 2000,
    };
  },
  updateUtilityFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error updating the Utility.',
      timeout: 3000,
    };
  },
  validateUtilityFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'Please complete all required fields',
      timeout: 3000,
    };
  },
  fetchAllUtilitiesSucceeded(state, { utilities }) {
    state.utilities = utilities;
  },
  fetchAllUtilitiesFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error fetching the Utilities.',
      timeout: 3000,
    };
  },
  fetchUtilityByIdSucceeded(state, { utility }) {
    state.utility = utility;
  },
  fetchUtilityByIdFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error fetching the Utilities.',
      timeout: 3000,
    };
  },
  deleteUtilityByIdSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The utility has been deleted successfully!',
      timeout: 2000,
    };
  },
  deleteUtilityByIdFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error deleting the Utility.',
      timeout: 3000,
    };
  },
};
