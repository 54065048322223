<template>
  <div>
    <v-layout>
      <v-icon
        large
        class="mr-3"
        :color="iconColor"
      >
        {{ icon }}
      </v-icon>
      <span class="display-1"> {{ title }} </span>
    </v-layout>

    <v-layout>
      <span class="subheading"> {{ subtitle }} </span>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    icon: { type: String, default: '' },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    color: { type: String, default: '' },
  },
  computed: {
    iconColor() {
      return this.color ? this.color : 'primary';
    },
  },
};
</script>
