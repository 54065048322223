export default {
  startIsLoading(state, method) {
    if (method) {
      state.isLoading[method] = true;
    } else {
      state.isLoading.default = true;
    }
  },
  stopIsLoading(state, method) {
    if (method) {
      state.isLoading[method] = false;
    } else {
      state.isLoading.default = false;
    }
  },
};
