<template>
  <v-select
    single-line
    filled
    dense
    background-color="inputGrey"
    item-text="name"
    :items="states"
    :value="value"
    v-bind="$attrs"
    @input="triggerEvent"
  />
</template>

<script>
import { UsaStates } from 'usa-states';

const usaStates = new UsaStates();

export default {
  props: {
    value: { type: String, default: '' },
  },
  data: () => ({
    states: usaStates.states,
  }),
  methods: {
    triggerEvent(event) {
      this.$emit('input', event);
    },
  },
};
</script>
