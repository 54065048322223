import { baseURL } from '@/constants';

export default {
  baseUrl: baseURL,
  authentication: {
    error: false,
    message: '',
  },
  companies: [],
  user: {},
  users: [],
  utility: {},
  utilities: [],
  utilityProviders: [],
  utilityAccounts: [],
  utilityInvoices: [],
  utilityTypes: [],
  role: {},
  roles: [],
  vendor: {},
  vendors: [],
  properties: [],
  property: {},
  propertyManagers: [],
  propertyManager: {},
  invoices: [],
  invoice: {},
  inquiries: [],
  notifications: [],
  notification: {},
  confirmation: {},
  sections: {
    default: '/',
    items: [],
  },
  loading: false,
  isLoading: {},
  settings: {},
};

export const defaultState = {
  baseUrl: baseURL,
  authentication: {
    error: false,
    message: '',
  },
  companies: [],
  user: {},
  users: [],
  utilities: [],
  utilityProviders: [],
  utilityAccounts: [],
  utilityInvoices: [],
  utilityTypes: [],
  role: {},
  roles: [],
  vendor: {},
  vendors: [],
  properties: [],
  property: {},
  propertyManagers: [],
  propertyManager: {},
  invoices: [],
  invoice: {},
  inquiries: [],
  notifications: [],
  notification: {},
  confirmation: {},
  sections: {
    default: '/',
    items: [],
  },
  loading: false,
  isLoading: {},
  settings: {},
};
