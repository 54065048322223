<template>
  <v-app
    app
    class="primary"
  >
    <v-main>
      <v-container fill-height>
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm8
            md5
            xl3
            class="authentication px-5 py-4 elevalion-20"
          >
            <v-layout
              justify-center
              class="mb-4"
            >
              <div>
                <v-img
                  :src="require('@/assets/logo-simple.svg')"
                  width="150"
                />
              </div>
            </v-layout>
            <router-view />
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'ApplicationAuthentication',
  components: {},
};
</script>

<style lang="scss">
@import '../scss/_colors.scss';

.authentication {
  background-color: #fff;
  border-radius: 3px;

  &__subtitle {
    color: $txtLightGrey;
  }

  &__warning {
    border-radius: 3px;
  }
}
</style>
