<template>
  <v-flex v-bind="$attrs">
    <slot />
  </v-flex>
</template>

<script>
export default {
  name: 'CardSection',
};
</script>
