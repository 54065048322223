<template>
  <div class="toolbar">
    <v-app-bar
      style="z-index: 1"
      app
      color="bgLightGrey"
      class="toolbar__background"
    >
      <v-toolbar-title class="toolbar__breadcrumbs">
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
        />
      </v-toolbar-title>
      <v-spacer />
      <Actions :items="actionButtons" />
    </v-app-bar>
  </div>
</template>

<script>
import Actions from '@/components/general/ToolbarActions.vue';

export default {
  name: 'ApplicationToolbar',
  components: {
    Actions,
  },
  computed: {
    notifications() {
      return this.$store.state.notifications;
    },
    breadcrumbs() {
      const pathArray = this.$route.path.split('/');
      pathArray.shift();

      const breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        const indexWithinBounds = (idx + 1 < this.$route.matched.length);
        if (indexWithinBounds) {
          breadcrumbArray.push({
            text: this.$route.matched[idx + 1].meta.breadcrumb || path,
            href: this.$route.matched[idx + 1].path || null,
          });
        }

        return breadcrumbArray;
      }, []);

      breadcrumbs[breadcrumbs.length - 1].disabled = true;

      return breadcrumbs;
    },
    actionButtons() {
      return this.$route.meta.actions;
    },
  },
  created() {
    this.getAllNotifications();
  },
  methods: {
    async getAllNotifications() {
      await this.$store.dispatch('fetchAllNotificationsInterval', { user: this.$store.state.user.id });
    },
  },
};
</script>

<style lang="scss">
@import '../scss/_colors.scss';

.toolbar{
  &__background {
    -webkit-box-shadow:
      0 2px 1px -1px rgb(237,238,240),
      0 1px 1px 0 rgb(237,238,240),
      0 1px 3px 0 rgb(237,238,240) !important;

    box-shadow:
      0 2px 1px -1px rgb(237,238,240),
      0 1px 1px 0 rgb(237,238,240),
      0 1px 3px 0 rgb(237,238,240) !important;
  }

  &__breadcrumbs {
    a {
      font-size: 20px;
    }
  }
}
</style>
