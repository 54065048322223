import router from '@/router';

import { repositoryFactory } from '@/repositories/repositoryFactory';

const authRepository = repositoryFactory.auth;

const getHostUrl = () => {
  const { protocol, hostname, port } = window.location;
  const baseUrl = `${protocol}//${hostname}`;
  return port === '80' ? baseUrl : `${baseUrl}:${port}`;
};

export default {
  async authenticate({ commit, dispatch }, { username, password }) {
    commit('startLoading');
    try {
      const authData = await authRepository.authWithCredentials(username, password);
      commit('authenticationSucceeded', { authData });
      dispatch('configureUser');
    } catch (error) {
      localStorage.removeItem('token');
      commit('resetState');
      const message = error.response?.data?.message[0]?.messages[0]?.message || error.message;
      commit('authenticationFailed', { message });
    }
  },

  clearAuthenticationError({ commit }) {
    commit('authenticationReset');
  },

  logout({ commit }) {
    router.push({ name: 'login' });
    localStorage.removeItem('token');
    commit('resetState');
  },

  async forgotPassword(context, email) {
    await authRepository.forgotPassword(email);
  },

  async sendInvite(context, email) {
    const resetPasswordUrl = `${getHostUrl()}/auth/send-invite`;
    await authRepository.sendInvite(email, resetPasswordUrl);
  },

  async resetPassword({ commit, dispatch }, { code, password, passwordConfirmation }) {
    try {
      const authData = await authRepository.resetPassword(code, password, passwordConfirmation);
      commit('resetState');
      commit('authenticationSucceeded', { authData });
      dispatch('configureUser');
    } catch (error) {
      const message = error.response?.data?.message[0]?.messages[0]?.message || error.message;
      commit('authenticationFailed', { message });
      commit('stopLoading');
    }
  },

  async registerUser(context, user) {
    return await authRepository.register(user);
  },
};
