import router from '@/router';
import { repositoryFactory } from '@/repositories/repositoryFactory';

const vendorRepository = repositoryFactory.vendor;
const fileRepository = repositoryFactory.file;
const userRepository = repositoryFactory.user;

const buildFilePayload = (id, file) => ({
  files: file,
  refId: id,
  ref: 'vendor',
  field: 'w_9_form',
});

export default {
  async fetchAllVendors({ commit }, query) {
    commit('startLoading');
    const vendors = await vendorRepository.getAll(query);
    commit('fetchAllVendorsSucceeded', { vendors });
    commit('stopLoading');
    return vendors;
  },

  async fetchVendor({ commit }, { id }) {
    commit('startLoading');
    const vendor = await vendorRepository.get(id);
    commit('fetchVendorSucceeded', { vendor });
    commit('stopLoading');
  },

  async fetchOwnVendor({ commit, state }) {
    commit('startLoading');
    const vendor = await vendorRepository.getAll({ user: state.user.id });
    commit('fetchVendorSucceeded', { vendor: vendor[0] });
    commit('stopLoading');
  },

  async updateVendor({ commit }, { vendor, file }) {
    commit('startLoading');
    const updatedVendor = await vendorRepository.update(vendor.id, vendor);
    if (file) {
      const payload = buildFilePayload(vendor.id, file);
      await fileRepository.upload(payload);
    }
    commit('updateVendorSucceeded', { vendor: updatedVendor });
    commit('stopLoading');
  },

  async createVendor({ commit, dispatch }, { vendor }) {
    const VENDOR_ROLE = 5;

    const user = (({
      contactFirstName, contactMiddleName, contactLastName, contactEmail,
    }) => ({
      firstname: contactFirstName,
      middlename: contactMiddleName,
      lastname: contactLastName,
      username: contactEmail,
      email: contactEmail,
      password: Math.random().toString(36).slice(-8),
      role: VENDOR_ROLE,
    }))(vendor);

    commit('startLoading');
    const registeredUser = await userRepository.create(user);
    dispatch('sendInvite', registeredUser.email);
    await vendorRepository.create({ ...vendor, user: registeredUser.id });
    commit('createVendorSucceeded');

    commit('stopLoading');
  },

  async massCreateVendor({ commit }, { vendors }) {
    commit('startLoading');
    // eslint-disable-next-line no-console
    let response = await vendorRepository.massCreate(vendors);
    commit('createCompanySucceeded', { vendors });
    commit('stopLoading');
    return response;
  },

  async deleteVendor({ commit }, { vendor }) {
    await vendorRepository.delete(vendor.id);
    await userRepository.delete(vendor.user.id);

    commit('deleteVendorSucceeded');
    router.push('/vendors');
  }
  ,
};
