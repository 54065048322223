export default {
  createUtilityInvoiceSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The utility has been created successfully!',
      timeout: 2000,
    };
  },
  createUtilityInvoiceFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error creating the Utility.',
      timeout: 3000,
    };
  },
  updateUtilityInvoiceSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The utility has been updated successfully!',
      timeout: 2000,
    };
  },
  updateUtilityInvoiceFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error updating the Utility.',
      timeout: 3000,
    };
  },
  validateUtilityInvoiceFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'Please complete all required fields',
      timeout: 3000,
    };
  },
  fetchAllUtilityInvoicesSucceeded(state, { utilityInvoices }) {
    state.utilityInvoices = utilityInvoices;
  },
  fetchAllUtilityInvoicesFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error fetching the Utilities.',
      timeout: 3000,
    };
  },
  fetchUtilityInvoiceByIdSucceeded(state, { utilityInvoice }) {
    state.utilityInvoice = utilityInvoice;
  },
  fetchUtilityInvoiceByIdFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error fetching the Utilities.',
      timeout: 3000,
    };
  },
  deleteUtilityInvoiceByIdSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The utility has been deleted successfully!',
      timeout: 2000,
    };
  },
  deleteUtilityInvoiceByIdFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error deleting the Utility.',
      timeout: 3000,
    };
  },
};
