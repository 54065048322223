import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import state from './state';
import mutations from './mutations';
import actions from './actions';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
});

export default new Vuex.Store({
  state,
  mutations,
  actions,
  plugins: [vuexLocalStorage.plugin],
});
