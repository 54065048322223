<template>
  <div class="body-1">
    <template v-if="Array.isArray(description)">
      <p
        v-for="(section, idx) in description"
        :key="idx"
        :class="marginBottom"
      >
        {{ section }}
      </p>
    </template>
    <template v-else>
      <p
        :class="marginBottom"
      >
        {{ description }}
      </p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CardSectionDescription',
  props: {
    description: { type: [Array, String], default: '' },
    separation: { type: String, default: '' },
  },
  computed: {
    marginBottom() {
      return this.separation ? `mb-${this.separation}` : '';
    },
  },
};
</script>
