<template>
  <v-flex v-bind="$attrs">
    <div>
      <v-tooltip
        bottom
        :disabled="tooltip === null"
      >
        <template #activator="{on, attrs}">
          <span
            v-bind="attrs"
            class="subheading font-weight-medium"
            v-on="on"
          >
            {{ label }}
            <slot />
          </span>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </div>
  </v-flex>
</template>

<script>
export default {
  name: 'ItemTemplate',

  props: {
    label: { type: String, default: '' },
    tooltip: { type: String, default: null },
  },
};
</script>
