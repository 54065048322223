export default {
  createUtilityAccountSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The utility has been created successfully!',
      timeout: 2000,
    };
  },
  createUtilityAccountFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error creating the Utility.',
      timeout: 3000,
    };
  },
  updateUtilityAccountSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The utility has been updated successfully!',
      timeout: 2000,
    };
  },
  updateUtilityAccountFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error updating the Utility.',
      timeout: 3000,
    };
  },
  validateUtilityAccountFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'Please complete all required fields',
      timeout: 3000,
    };
  },
  fetchAllUtilityAccountsSucceeded(state, { utilityAccounts }) {
    state.utilityAccounts = utilityAccounts;
  },
  fetchAllUtilityAccountsFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error fetching the Utilities.',
      timeout: 3000,
    };
  },
  fetchUtilityAccountByIdSucceeded(state, { utilityAccount }) {
    state.utilityAccount = utilityAccount;
  },
  fetchUtilityAccountByIdFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error fetching the Utilities.',
      timeout: 3000,
    };
  },
  deleteUtilityAccountByIdSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The utility has been deleted successfully!',
      timeout: 2000,
    };
  },
  deleteUtilityAccountByIdFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error deleting the Utility.',
      timeout: 3000,
    };
  },
};
