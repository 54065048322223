<template>
  <v-container text-xs-center>
    <v-layout align-center>
      <v-flex>
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'DisplayLoader',
};
</script>
