import { repositoryFactory } from '@/repositories/repositoryFactory';

const utilityRepository = repositoryFactory.utility;

export default {
  async fetchAllUtilities({ commit }, query) {
    commit('startLoading');
    const utilities = await utilityRepository.getAll(query);
    commit('fetchAllUtilitiesSucceeded', { utilities });
    commit('stopLoading');
  },
  async fetchUtilityById({ commit }, { id }) {
    commit('startLoading');
    const utility = await utilityRepository.get(id);
    commit('fetchUtilityByIdSucceeded', { utility });
    commit('stopLoading');
  },
  async deleteUtilityById({ commit }, { id }) {
    await utilityRepository.delete(id);
    commit('deleteUtilitySucceeded');
  },
  async createUtility({ commit }, { utility }) {
    commit('startLoading');
    await utilityRepository.create(utility);
    commit('createUtilitySucceeded');
    commit('stopLoading');
  },
  async updateUtility({ commit }, { utility }) {
    commit('startLoading');
    await utilityRepository.update(utility.id, utility);
    commit('updateUtilitySucceeded');

    commit('stopLoading');
  },
};
