// filters.js
import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatAsCurrency', (value) => {
  if (!value) {
    return;
  }
  return `$${value}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
});

Vue.filter('formatDate', (date) => {
  if (!date) {
    return;
  }
  return moment(date).format('MMMM/DD/YYYY');
});

Vue.filter('formatAddress', (item) => {
  if (!item) {
    return;
  }
  return item.join(', ');
});

Vue.filter('formatName', (item) => {
  if (!item) {
    return;
  }
  return `${item.firstname || ''} ${item.middlename || ''} ${item.lastname || ''}`.trim();
});

Vue.filter('formatPhone', (item) => {
  if (!item) {
    return;
  }
  item = item.toString();
  return `(${item.substr(0, 3)}) ${item.substr(3, 3)}-${item.substr(6, 4)}`;
});

Vue.filter('formatMessage', (item) => {
  if (!item) {
    return;
  }
  return item.length > 40 ? `${item.substr(0, 40)}...` : item;
});

export default {};
