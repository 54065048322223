export default {
  createUtilityProviderSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The utility has been created successfully!',
      timeout: 2000,
    };
  },
  createUtilityProviderFailed(state, message) {
    state.notification = {
      display: true,
      type: 'error',
      message: `There was an error creating the Utility Provider error: ${message}`,
      timeout: 3000,
    };
  },
  updateUtilityProviderSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The utility has been updated successfully!',
      timeout: 2000,
    };
  },
  updateUtilityProviderFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error updating the Utility.',
      timeout: 3000,
    };
  },
  validateUtilityProviderFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'Please complete all required fields',
      timeout: 3000,
    };
  },
  fetchAllUtilityProvidersSucceeded(state, { utilityProviders }) {
    state.utilityProviders = utilityProviders;
  },
  fetchAllUtilityProvidersFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error fetching the Utilities.',
      timeout: 3000,
    };
  },
  fetchUtilityProviderByIdSucceeded(state, { utilityProvider }) {
    state.utilityProvider = utilityProvider;
  },
  fetchUtilityProviderByIdFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error fetching the Utilities.',
      timeout: 3000,
    };
  },
  deleteUtilityProviderByIdSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The utility has been deleted successfully!',
      timeout: 2000,
    };
  },
  deleteUtilityProviderByIdFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error deleting the Utility.',
      timeout: 3000,
    };
  },
};
