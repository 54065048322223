<template>
  <v-layout
    row
    justify-center
  >
    <v-dialog
      v-model="isActive"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Pending changes
        </v-card-title>
        <v-card-text>
          You have changes without save.
          If you leave now your changes will be lost.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="leave"
          >
            Leave
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="stay"
          >
            Stay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ConfirmationDialog',
  computed: {
    isActive() { return this.$store.state.confirmation.display; },
  },
  methods: {
    ...mapActions(['stayConfirmationDialog', 'leaveConfirmationDialog']),
    stay() {
      this.stayConfirmationDialog();
    },
    leave() {
      this.leaveConfirmationDialog();
    },
  },
};
</script>
