import vendorMutations from '@/store/mutations/vendor';
import userMutations from '@/store/mutations/user';
import roleMutations from '@/store/mutations/role';
import invoiceMutations from '@/store/mutations/invoice';
import inquiryMutations from '@/store/mutations/inquiry';
import propertyMutations from '@/store/mutations/property';
import utilityMutations from '@/store/mutations/utility';
import propertyManagerMutations from '@/store/mutations/propertyManager';
import authorizationMutations from '@/store/mutations/authorization';
import companyMutations from '@/store/mutations/company';
import notificationMutations from '@/store/mutations/notification';
import notificationsMutations from '@/store/mutations/notifications';
import confirmationDialogMutations from '@/store/mutations/confirmationDialog';
import loadingMutations from '@/store/mutations/loading';
import isLoadingMutations from '@/store/mutations/isLoading';
import stateMutations from '@/store/mutations/state';
import settingsMutations from '@/store/mutations/settings';
import utilityProvider from '@/store/mutations/utilityProvider';
import utilityAccount from '@/store/mutations/utilityAccount';
import utilityInvoice from '@/store/mutations/utilityInvoice';
import utilityType from '@/store/mutations/utilityType';

export default {
  ...utilityType,
  ...utilityInvoice,
  ...utilityAccount,
  ...utilityProvider,
  ...vendorMutations,
  ...userMutations,
  ...roleMutations,
  ...invoiceMutations,
  ...inquiryMutations,
  ...propertyMutations,
  ...utilityMutations,
  ...propertyManagerMutations,
  ...authorizationMutations,
  ...companyMutations,
  ...notificationMutations,
  ...notificationsMutations,
  ...confirmationDialogMutations,
  ...loadingMutations,
  ...isLoadingMutations,
  ...stateMutations,
  ...settingsMutations,
};
