<template>
  <v-form ref="loginForm">
    <div>
      <h1 class="display-2">
        Welcome!
      </h1>
      <span class="subheading authentication__subtitle">Log in to continue</span>
    </div>
    <v-alert
      ref="alert"
      :value="isAuthorized"
      color="error"
      icon="fas fa-exclamation-circle"
      class="my-3 authentication__warning"
    >
      {{ $store.state.authentication.message }}
    </v-alert>

    <div class="mt-4">
      <h2 class="mb-1 text-xs-left font-weight-medium subheading">
        Email Address
      </h2>
      <v-text-field
        v-model="username"
        :rules="usernameRules"
        label="E.g. jhon@abc.com"
        type="text"
        single-line
        filled
        required
      />
      <h2 class="mb-1 text-xs-left font-weight-medium subheading">
        Password
      </h2>
      <v-text-field
        v-model="password"
        :rules="passwordRules"
        label="Your password"
        type="password"
        single-line
        filled
        required
      />
    </div>
    <div class="text-xs-right mb-4">
      <v-btn
        text
        small
        color="primary"
        class="text-none"
        to="forgot-password"
      >
        Forgot your password?
      </v-btn>
    </div>
    <v-btn
      block
      large
      color="primary"
      class="text-none"
      type="submit"
      :loading="isLoading"
      @click.stop.prevent="loginClick"
    >
      Log in
    </v-btn>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ApplicationLogin',
  data() {
    return {
      username: '',
      usernameRules: [
        (v) => !!v || 'Username is required',
      ],
      password: '',
      passwordRules: [
        (v) => !!v || 'Password is required',
      ],
    };
  },
  computed: {
    ...mapState(['authentication', 'loading']),
    isAuthorized() {
      return this.authentication.error;
    },
    isLoading() {
      return this.loading;
    },
  },
  beforeMount() {
    this.clearAuthenticationError();
    this.$store.state.loading = false;
  },
  methods: {
    ...mapActions(['authenticate', 'clearAuthenticationError']),
    async loginClick() {
      if (!this.isFormValid()) {
        return;
      }

      const payload = {
        username: this.username,
        password: this.password,
      };

      this.authenticate(payload);
    },
    isFormValid() {
      return this.$refs.loginForm.validate();
    },
  },
};
</script>
