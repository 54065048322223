import router from '@/router';

export default {
  resetConfirmation(state) {
    state.confirmation = {
      display: false,
      leave: false,
    };
  },
  displayConfirmationDialog(state, { nextUrl }) {
    state.confirmation = {
      display: true,
      nextUrl,
    };
  },
  stayConfirmationDialog(state) {
    state.confirmation = {
      display: false,
    };
  },
  leaveConfirmationDialog(state) {
    const { nextUrl } = state.confirmation;

    state.confirmation = {
      display: false,
      leave: true,
    };

    router.push(nextUrl);
  },
};
