import { repositoryFactory } from '@/repositories/repositoryFactory';

const userRepository = repositoryFactory.user;

export default {
  async fetchAllUsers({ commit }, payload) {
    commit('startLoading');
    try {
      const users = await userRepository.getAll(payload);
      commit('fetchAllUsersSucceeded', { users });
    } catch (error) {
      commit('createUserFailed');
    } finally {
      commit('stopLoading');
    }
  },

  async fetchUserById({ commit }, { id }) {
    commit('startLoading');
    try {
      const user = await userRepository.get(id);
      commit('fetchUserSucceeded', { user });
    } catch (error) {
      commit('createUserFailed');
    } finally {
      commit('stopLoading');
    }
  },

  async updateUser({ commit }, { id, ...user }) {
    commit('startLoading');
    try {
      const result = await userRepository.update(id, user);
      commit('updateUserSucceeded', result);
    } catch (error) {
      commit('updateUserFailed');
    } finally {
      commit('stopLoading');
    }
  },

  async createUser({ commit, dispatch }, { user }) {
    const password = Math.random().toString(36).substr(2, 8);
    commit('startLoading');
    try {
      const { user: registeredUser } = await dispatch('registerUser', { ...user, password });
      dispatch('sendInvite', registeredUser.email);
      commit('createUserSucceeded');
    } catch (error) {
      commit('createUserFailed');
    } finally {
      commit('stopLoading');
    }
  },

  async deleteUserById({ commit }, id) {
    try {
      await userRepository.delete(id);
      commit('deleteUserSucceeded');
    } catch (error) {
      commit('createUserFailed');
    }
  },
};
