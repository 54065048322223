export default {
  updateUserSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The user has been updated successfully!',
      timeout: 2000,
    };
  },
  updateUserFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an issue updating the user!',
      timeout: 2000,
    };
  },
  createUserSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The user has been created successfully!',
      timeout: 2000,
    };
  },
  createUserFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an issue creating the user!',
      timeout: 2000,
    };
  },
  deleteUserSucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The user has been deleted successfully!',
      timeout: 2000,
    };
  },
  deleteUserFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an issue deleting the user!',
      timeout: 2000,
    };
  },
  fetchUserSucceeded(state, { user }) {
    state.user = user;
  },
  fetchUserFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an issue fetching the user!',
      timeout: 2000,
    };
  },
  fetchAllUsersSucceeded(state, { users }) {
    state.users = users;
  },
  fetchAllUsersFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an issue deleting the user!',
      timeout: 2000,
    };
  },
};
