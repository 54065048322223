export default {
  createInquirySuccess(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The inquiry has been created successfully!',
      timeout: 2000,
    };
  },
  createInquiryFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an issue creating your inquiry!',
      timeout: 2000,
    };
  },
  validateInquiryFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'Please complete all required fields',
      timeout: 3000,
    };
  },
  fetchInquiriesSucceeded(state, { inquiries }) {
    state.inquiries = inquiries;
  },
  fetchInquirySucceeded(state, data) {
    state.inquiry = data;
  },
  deleteInquirySucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The inquiry was successfully deleted',
      timeout: 2000,
    };
  },
};
