export default ({
  install(Vue) {
    Vue.prototype.$msdut = { // eslint-disable-line no-param-reassign
      sortString(a, b) {
        return (a.toLowerCase() ?? '').localeCompare(b.toLowerCase() ?? '');
      },
      sortInt(a, b) {
        return a ?? 0 - b ?? 0;
      },
      sortDate(a, b) {
        return new Date(a ?? 0) - new Date(b ?? 0);
      },
      isUserAllowed: {

      },
      allowNumbers(e) {
        const charCode = e.key.charCodeAt;
        if (this.isCharNumber(charCode)) {
          e.preventDefault();
        }
      },
      isCharNumber(char) {
        return (char > 31 && (char < 48 || char > 57)) && char !== 46;
      },
      formatAddressLocation(data) {
        return [data.city, data.state, data.zip].filter((el) => !!el).join(', ') || '';
      },
      formatFullAddress(data) {
        return [
          data.streetAddress,
          data.interiorNumber,
          data.city,
          data.state,
          data.zip,
        ]
          .filter((el) => !!el)
          .join(', ') || '';
      },
    };
  },
});
