import { repositoryFactory } from '@/repositories/repositoryFactory';

const utilityTypeRepository = repositoryFactory.utilityType;

export default {
  async fetchAllUtilityTypes({ commit }, query) {
    commit('startLoading');
    const utilityTypes = await utilityTypeRepository.getAll(query);
    commit('fetchAllUtilityTypesSucceeded', { utilityTypes });
    commit('stopLoading');
  },
  async fetchUtilityTypeById({ commit }, { id }, store) {
    commit('startLoading');
    const utilityType = await utilityTypeRepository.get(id);
    if (store) {
      commit('fetchUtilityTypeByIdSucceeded', { utilityType });
    }
    commit('stopLoading');
    return utilityType;
  },
  async deleteUtilityTypeById({ commit }, { id }) {
    await utilityTypeRepository.delete(id);
    commit('deleteUtilityTypeSucceeded');
  },
  async createUtilityType({ commit }, { utilityType }) {
    commit('startLoading');
    await utilityTypeRepository.create(utilityType);
    commit('createUtilityTypeSucceeded');
    commit('stopLoading');
  },
  async updateUtilityType({ commit }, { utilityType }) {
    commit('startLoading');
    await utilityTypeRepository.update(utilityType.id, utilityType);
    commit('updateUtilityTypeSucceeded');

    commit('stopLoading');
  },
};
