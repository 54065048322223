<template>
  <v-layout justify-end>
    <div class="d-flex align-center">
      <div
        v-for="(button, idx) in items"
        :key="idx"
      >
        <div v-if="isAuthorized(button)">
          <v-btn
            v-if="button.type === 'button'"
            :to="button.link"
            color="primary"
            class="text-capitalize mx-2"
            append
            @click="button.function ? button.function : ''"
          >
            <v-icon
              v-if="button.icon"
              small
              left
              dark
            >
              {{ button.icon }}
            </v-icon>
            <span>{{ button.text }}</span>
          </v-btn>
          <v-menu
            v-if="button.type === 'dropdown'"
            offset-y
          >
            <template #activator="{on: onMenu}">
              <v-btn
                color="primary"
                class="text-capitalize mx-2"
                v-on="onMenu"
              >
                {{ button.text }}
                <v-icon right>
                  fas fa-angle-down
                </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-tooltip
                v-for="(action, nidx) in button.subactions"
                :key="nidx"
                bottom
                :disabled="action.subtitle == null"
              >
                <template #activator="{on: onTooltip}">
                  <v-list-item
                    append
                    :to="action.link"
                    v-on="{...onTooltip}"
                  >
                    <v-list-item-avatar>
                      <v-icon
                        small
                        left
                        color="primary"
                      >
                        {{ action.icon }}
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{ action.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>

                <span>{{ action.subtitle }}</span>
              </v-tooltip>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div>
        <v-menu
          bottom
          offset-y
        >
          <template #activator="{on}">
            <v-btn
              color="primary"
              fab
              small
              v-on="on"
            >
              <v-icon
                small
                dark
              >
                fas fa-bell
              </v-icon>
              <v-badge
                v-if="unreadNotifications > 0"
                color="red"
                :content="unreadNotifications"
              />
            </v-btn>
          </template>
          <v-list
            v-if="checkNotifications.length"
            text
            width="250px"
          >
            <div
              v-for="(notification, nidx) in checkNotifications"
              :key="nidx"
            >
              <v-list-item
                dense
                input-value="true"
              >
                <v-list-item-content
                  dense
                >
                  <v-list-item-title
                    class="text-start"
                    v-html="notification.title"
                  />
                  <v-list-item-subtitle
                    class="text-start"
                    v-html="notification.notificationMessage"
                  />
                </v-list-item-content>
                <v-btn
                  v-if="notification.url"
                  icon
                  :href="notification.url ? notification.url : false "
                >
                  <v-icon
                    color="primary"
                    small
                  >
                    fas fa-external-link-alt
                  </v-icon>
                </v-btn>
                <template v-if="!notification.read">
                  <v-badge
                    inline
                    dot
                    color="red"
                  />
                  <v-btn
                    icon
                    @click="setReadNotification(notification.id)"
                  >
                    <v-icon small>
                      fa-check
                    </v-icon>
                  </v-btn>
                </template>
                <v-btn
                  icon
                  @click="deleteNotification(notification.id)"
                >
                  <v-icon small>
                    fa-times
                  </v-icon>
                </v-btn>
              </v-list-item>
              <v-divider />
            </div>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ToolbarActions',
  props: {
    items: { type: Array, default: () => {} },
    defaultButtons: { type: Object, default: () => {} },
  },
  computed: {
    ...mapState(['notifications', 'role']),
    unreadNotifications() {
      return !this.notifications.length ? [] : this.notifications.filter((item) => !item.read).length;
    },
    checkNotifications() {
      return !this.notifications.length ? [] : this.notifications.slice(0).reverse();
    },
  },
  methods: {
    ...mapActions(['setReadNotification', 'deleteNotification']),
    isAuthorized(item) {
      if (!item.authorized) {
        return true;
      }
      return item.authorized.includes(this.role.type);
    },
  },
};
</script>
