<template>
  <v-app app>
    <Sidebar />
    <Toolbar />
    <Content />
  </v-app>
</template>

<script>
import Sidebar from '../components/ApplicationSidebar.vue';
import Toolbar from '../components/ApplicationToolbar.vue';
import Content from '../components/ApplicationContent.vue';

export default {
  name: 'ApplicationDashboard',
  components: {
    Sidebar,
    Toolbar,
    Content,
  },
};
</script>
