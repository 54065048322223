export default {
  fetchAllCompaniesSucceeded(state, { companies }) {
    state.companies = companies;
  },
  fetchAllCompaniesFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error fetching the Companies.',
      timeout: 3000,
    };
  },
  fetchCompanyByIDSucceeded(state, { company }) {
    state.company = company;
  },
  fetchCompanyByIDFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error fetching the Company.',
      timeout: 3000,
    };
  },
  deleteCompanySucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The company has been deleted successfully!',
      timeout: 2000,
    };
  },
  deleteCompanyFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error deleting the Company.',
      timeout: 3000,
    };
  },
  createCompanySucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The company has been created successfully!',
      timeout: 2000,
    };
  },
  createCompanyFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error creating the Company.',
      timeout: 3000,
    };
  },
  updateCompanySucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The company has been updated successfully!',
      timeout: 2000,
    };
  },
  updateCompanyFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error updating the Company.',
      timeout: 3000,
    };
  },
};
