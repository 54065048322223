import router from '@/router';
import {
  ADMIN_MOS,
  COORDINADOR_MOS,
  PROCESAMIENTO_MOS,
  RECEPCION_MOS,
  ADMIN_CLIENTE,
  PROPERTY_MANAGER_CLIENTE,
  STAFF_CLIENT,
  VENDOR_EXTERNAL,
} from '@/constants';

export default {
  configureUser({ state, dispatch, commit }) {
    const { role } = state;

    if (role.type === VENDOR_EXTERNAL) {
      dispatch('authorizeVendor');
    } else if (role.type === ADMIN_MOS) {
      dispatch('authorizeAdminMOS');
    } else if (role.type === COORDINADOR_MOS) {
      dispatch('authorizeCoordinatorMos');
    } else if (role.type === PROCESAMIENTO_MOS) {
      dispatch('authorizeProcessingMos');
    } else if (role.type === RECEPCION_MOS) {
      dispatch('authorizeReceptionMos');
    } else if (role.type === ADMIN_CLIENTE) {
      dispatch('authorizeClientAdmin');
    } else if (role.type === STAFF_CLIENT) {
      dispatch('authorizeClientStaff');
    } else if (role.type === PROPERTY_MANAGER_CLIENTE) {
      dispatch('authorizePropertyManager');
    } else {
      dispatch('failAuthorization');
    }

    commit('stopLoading');
  },

  async authorizeVendor({ dispatch, commit, state }) {
    await dispatch('fetchOwnVendor');
    commit('authorizeVendor');
    router.push(state.sections.default);
  },

  authorizeAdminMOS({ commit, state }) {
    commit('authorizeAdminMos');
    router.push(state.sections.default);
  },

  authorizeCoordinatorMos({ commit, state }) {
    commit('authorizeCoordinatorMos');
    router.push(state.sections.default);
  },

  authorizeProcessingMos({ commit, state }) {
    commit('authorizeProcessingMos');
    router.push(state.sections.default);
  },

  authorizeReceptionMos({ commit, state }) {
    commit('authorizeReceptionMos');
    router.push(state.sections.default);
  },

  authorizeClientAdmin({ commit, state }) {
    commit('authorizeClientAdmin');
    router.push(state.sections.default);
  },

  authorizeClientStaff({ commit, state }) {
    commit('authorizeClientStaff');
    router.push(state.sections.default);
  },

  async authorizePropertyManager({ commit, state, dispatch }) {
    await dispatch('fetchOwnPropertyManager');
    commit('authorizePropertyManager');
    router.push(state.sections.default);
  },

  failAuthorization({ commit }) {
    commit('authenticationFailed', {
      message: 'Unable to verify user permissions',
    });
  },
};
