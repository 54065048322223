<template>
  <div>
    <v-text-field
      v-if="phone"
      class="custom-label"
      single-line
      dense
      readonly
      filled
      :value="text | formatPhone"
      v-bind="$attrs"
    />

    <!-- Default -->
    <v-text-field
      v-else-if="date"
      class="custom-label"
      single-line
      dense
      readonly
      filled
      :value="formatDate(value)"
      v-bind="$attrs"
    />

    <!-- Default -->
    <v-text-field
      v-else
      class="custom-label"
      single-line
      dense
      readonly
      filled
      :value="text"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name:'LabelTemplate',
  props: {
    value: { type: [String, Number], default: '' },
    phone: { type: Boolean },
    date: { type: Boolean },
    dateFormat: { type: String, default: '' },
  },
  data: () => ({
    defautlDateFormat: 'MM/DD/Y',
    emptyValue: '-',
  }),
  computed: {
    text() {
      return !this.value ? this.emptyValue : this.value;
    },
  },
  methods: {
    formatDate(date) {
      if (!date) {
        return this.emptyValue;
      }

      return this.dateFormat
        ? moment.utc(date).format(this.dateFormat)
        : moment.utc(date).format(this.defautlDateFormat);
    },
  },
};
</script>

<style lang="scss">
.custom-label {
  .v-input__slot:before {
    border-style: none !important;
  }
}
</style>
