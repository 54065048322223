<template>
  <v-container class="settings text-xs-left">
    <Loader v-if="isLoading" />

    <div v-else>
      <v-layout>
        <v-flex>
          <v-form ref="form">
            <SectionTitle
              icon="fas fa-user-cog"
              class="mb-4"
              :title="vendor.name"
            />

            <Card>
              <CardTile>
                <CardSection xs4>
                  <CardTitle :title="labels.address.title" />
                  <CardDescription :description="labels.address.description" />
                </CardSection>
                <CardSection xs8>
                  <CardTile>
                    <Item label="Street Address">
                      <InputText
                        v-model="vendor.addressStreet"
                        label="Street"
                      />
                    </Item>
                    <Item label="Apt / Suite / Other">
                      <InputText
                        v-model="vendor.interiorNumber"
                        label="Int. Number"
                      />
                    </Item>
                  </CardTile>
                  <CardTile>
                    <Item label="Zip Code">
                      <InputText
                        v-model="vendor.zip"
                        label="Zip"
                      />
                    </Item>
                    <Item label="City">
                      <InputText
                        v-model="vendor.city"
                        label="City"
                      />
                    </Item>
                  </CardTile>
                  <CardTile>
                    <Item
                      label="State"
                      xs6
                    >
                      <InputStates
                        v-model="vendor.state"
                      />
                    </Item>
                  </CardTile>
                </CardSection>
              </CardTile>
            </Card>

            <Card>
              <CardTile>
                <CardSection xs4>
                  <CardTitle :title="labels.contact.title" />
                  <CardDescription :description="labels.contact.description" />
                </CardSection>
                <CardSection xs8>
                  <CardTile>
                    <Item label="Contact Full Name">
                      <InputText
                        v-model="vendor.contactName"
                        label="Full Name"
                      />
                    </Item>
                  </CardTile>
                  <CardTile>
                    <Item label="Phone">
                      <InputText
                        v-model="vendor.contactPhone"
                        label="Phone"
                      />
                    </Item>
                    <Item label="Mobile Phone">
                      <InputText
                        v-model="vendor.contactMobile"
                        label="Mobile Phone"
                      />
                    </Item>
                  </CardTile>
                  <CardTile>
                    <Item label="Email">
                      <Label :value="vendor.contactEmail" />
                    </Item>
                  </CardTile>
                </CardSection>
              </CardTile>
            </Card>

            <Card>
              <CardTile>
                <CardSection xs4>
                  <CardTitle :title="labels.billing.title" />
                  <CardDescription :description="labels.billing.description" />
                </CardSection>
                <CardSection xs8>
                  <CardTile>
                    <Item label="Routing Number">
                      <InputText
                        v-model="vendor.routingNumber"
                        label="Routing Number"
                      />
                    </Item>
                  </CardTile>
                  <CardTile>
                    <Item label="Account Number">
                      <InputText
                        v-model="vendor.accountNumber"
                        label="Account Number"
                      />
                    </Item>
                  </CardTile>
                </CardSection>
              </CardTile>
            </Card>

            <Card>
              <CardTile>
                <CardSection xs4>
                  <CardTitle :title="labels.w9.title" />
                  <CardDescription :description="labels.w9.description" />
                </CardSection>
                <CardSection
                  v-if="w9Url"
                  xs4
                >
                  <div class="settings__submit">
                    <FileViewer
                      btn-text="View Current Form"
                      btn-icon="fas fa-file-download"
                      btn-color="btnDarkGrey"
                      :src="w9Url"
                    />
                  </div>
                </CardSection>
                <CardSection xs4>
                  <Dropzone
                    btn-text="Upload New"
                    :image="image"
                    @uploadFile="uploadW9Form"
                  />
                </CardSection>
              </CardTile>
            </Card>
          </v-form>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-spacer />
        <v-btn
          large
          color="primary"
          class="font-weight-bold text-capitalize"
          :loading="isLoading"
          @click="submit()"
        >
          submit for review
        </v-btn>
      </v-layout>
      <Notification />
      <Confirmation />
    </div>
  </v-container>
</template>

<script>
import Loader from '@/components/general/DisplayLoader.vue';
import Card from '@/components/general/custom-card/CardTemplate.vue';
import CardTile from '@/components/general/custom-card/CardTile.vue';
import CardSection from '@/components/general/custom-card/CardSection.vue';
import CardTitle from '@/components/general/custom-card/CardTitle.vue';
import CardDescription from '@/components/general/custom-card/CardSectionDescription.vue';
import SectionTitle from '@/components/general/SectionTitle.vue';
import Item from '@/components/general/custom-card/ItemTemplate.vue';
import InputText from '@/components/general/inputs/InputText.vue';
import InputStates from '@/components/general/inputs/InputStates.vue';
import Label from '@/components/general/custom-card/LabelTemplate.vue';
import Dropzone from '@/components/general/DropzoneTemplate.vue';
import Confirmation from '@/components/general/ConfirmationDialog.vue';
import Notification from '@/components/general/DisplayNotification.vue';
import FileViewer from '@/components/general/FileViewer.vue';
import image from '@/assets/document.svg';

export default {
  name: 'AccountSettings',
  components: {
    Loader,
    Card,
    CardTile,
    CardSection,
    CardTitle,
    CardDescription,
    Item,
    InputText,
    InputStates,
    Label,
    SectionTitle,
    Dropzone,
    Confirmation,
    Notification,
    FileViewer,
  },
  data() {
    return {
      vendor: {},
      fileToUpload: null,
      labels: {
        address: {
          title: 'Address',
          description: [
            'This is the address of your business. This information will be used when processing your invoices, please be sure to instert the data correctly.',
          ],
        },
        contact: {
          title: 'Contact',
          description: [
            'Information of the person that is going to be contacted in case there is any issue with your invoices.',
          ],
        },
        billing: {
          title: 'Billing',
          description: [
            'Bank account information. All payments will be done to this account.',
          ],
        },
        w9: {
          title: 'W9 Form',
          description: [
            'Businesses can use Form W-9 to request information from contractors they hire.',
            'Make sure to upload your W9, otherwise we will not be able to process your data. You can fill your W9 Form in the following link: https://www.irs.gov/pub/irs-pdf/fw9.pdf.',
            'You can upload files with the following formats: PDF, JPG, PNG, JPEG. ',
          ],
        },
      },
      image,
    };
  },
  computed: {
    w9Url() {
      const { w9Form } = this.$store.state.vendor;
      return this.vendor.w9Form && w9Form.length ? w9Form[0].url : null;
    },
    vendorId() {
      return this.$store.state.vendor.id;
    },
    isLoading() {
      return this.$store.state.loading;
    },
  },
  beforeMount() {
    this.fetchVendorInfo();
  },
  methods: {
    uploadW9Form(file) {
      this.fileToUpload = file;
    },
    submit() {
      this.$store.dispatch('updateVendor', { vendor: this.vendor, file: this.fileToUpload });
    },
    buildVendorFromStore() {
      const { vendor } = this.$store.state;
      return {
        id: vendor.id,
        name: vendor.name,
        addressStreet: vendor.addressStreet,
        interiorNumber: vendor.interiorNumber,
        city: vendor.city,
        state: vendor.state,
        zip: vendor.zip,
        contactName: vendor.contactName,
        contactPhone: vendor.contactPhone,
        contactMobile: vendor.contactMobile,
        contactEmail: vendor.contactEmail,
        routingNumber: vendor.routingNumber,
        accountNumber: vendor.accountNumber,
        w9Form: vendor.w9Form,
      };
    },
    async fetchVendorInfo() {
      await this.$store.dispatch('fetchVendor', { id: this.vendorId });
      this.vendor = this.buildVendorFromStore();
    },
  },

};
</script>

<style lang="scss">
@import '../../scss/_colors.scss';

.settings {
  &__submit {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 2px solid $bgDarkGrey;
  }
}
</style>
