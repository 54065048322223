import {
  COMPANIES_SECTION,
  VENDORS_SECTION,
  PROPERTY_MANAGERS_SECTION,
  MY_INVOICES_SECTION,
  INVOICES_SECTION,
  PROPERTIES_SECTION,
  UTILITIES_SECTION,
  USERS_SECTION,
  INQUIRIES_SECTION,
  NOTIFICATIONS_SECTION,
} from '@/constants';

export default {
  authorizeVendor(state) {
    state.sections = {
      default: '/invoices',
      items: [MY_INVOICES_SECTION],
    };
  },
  authorizeAdminMos(state) {
    state.sections = {
      default: '/companies',
      items: [
        COMPANIES_SECTION,
        VENDORS_SECTION,
        PROPERTY_MANAGERS_SECTION,
        INVOICES_SECTION,
        PROPERTIES_SECTION,
        UTILITIES_SECTION,
        USERS_SECTION,
        INQUIRIES_SECTION,
        NOTIFICATIONS_SECTION,
      ],
    };
  },
  authorizeCoordinatorMos(state) {
    state.sections = {
      default: '/properties',
      items: [
        PROPERTIES_SECTION,
        PROPERTY_MANAGERS_SECTION,
        UTILITIES_SECTION,
        USERS_SECTION,
        INVOICES_SECTION,
        INQUIRIES_SECTION,
      ],
    };
  },
  authorizeReceptionMos(state) {
    state.sections = {
      default: '/properties',
      items: [
        PROPERTIES_SECTION,
        UTILITIES_SECTION,
        INVOICES_SECTION,
        INQUIRIES_SECTION,
      ],
    };
  },
  authorizeProcessingMos(state) {
    state.sections = {
      default: '/properties',
      items: [
        PROPERTIES_SECTION,
        VENDORS_SECTION,
        UTILITIES_SECTION,
        INVOICES_SECTION,
        INQUIRIES_SECTION,
      ],
    };
  },
  authorizeClientAdmin(state) {
    state.sections = {
      default: '/properties',
      items: [
        PROPERTIES_SECTION,
        VENDORS_SECTION,
        UTILITIES_SECTION,
        INVOICES_SECTION,
      ],
    };
  },
  authorizeClientStaff(state) {
    state.sections = {
      default: '/properties',
      items: [
        PROPERTIES_SECTION,
        VENDORS_SECTION,
        UTILITIES_SECTION,
        INVOICES_SECTION,
      ],
    };
  },
  authorizePropertyManager(state) {
    state.sections = {
      default: '/properties',
      items: [
        PROPERTIES_SECTION,
        VENDORS_SECTION,
        INVOICES_SECTION,
      ],
    };
  },
  authenticationSucceeded(state, { authData }) {
    localStorage.setItem('token', authData.jwt);
    state.user = authData.user;
    state.role = authData.user.role;
    state.authentication = {
      error: false,
      message: '',
    };
  },
  authenticationFailed(state, { message }) {
    state.authentication = {
      error: true,
      message,
    };
  },
  authenticationReset(state) {
    state.authentication = {
      error: false,
      message: '',
    };
  },
};
