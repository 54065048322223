export default {
  deletePropertySucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The property have been deleted successfully!',
      timeout: 2000,
    };
  },
  deletePropertyFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error deleting the Property.',
      timeout: 3000,
    };
  },
  createPropertySucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The property has been created successfully!',
      timeout: 2000,
    };
  },
  createPropertyFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error creating the Property.',
      timeout: 3000,
    };
  },
  updatePropertySucceeded(state) {
    state.notification = {
      display: true,
      type: 'success',
      message: 'The property has been updated successfully!',
      timeout: 2000,
    };
  },
  validatePropertyFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'Please complete all required fields',
      timeout: 3000,
    };
  },
  updatePropertyFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error updating the Property.',
      timeout: 3000,
    };
  },
  fetchAllPropertiesSucceeded(state, { properties }) {
    state.properties = properties;
  },
  fetchAllPropertiesFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error fetching the Properties.',
      timeout: 3000,
    };
  },
  fetchPropertyByIdSucceeded(state, { property }) {
    state.property = property;
  },
  fetchPropertyByIdFailed(state) {
    state.notification = {
      display: true,
      type: 'error',
      message: 'There was an error fetching the Property.',
      timeout: 3000,
    };
  },
};
